import Configuration from "../../../../config.js"; // outside "src"

import React from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";

import MainMenu from "./MainMenu.jsx";

import "@assets/styles/navigation.scss";

/**
 * Menu is a list of objects which is used to construct the actual main menu.
 * Link paths in the menu are also defined in the file ui/src/containers/Main.jsx,
 * which defines application's main routing.
 *
 * Menu supports link and submenu items. Item format described below:
 *
 * Link:
 * ```
 * {
 *   title: 'Title string',     // A string or translation id (Integer)
 *   icon: 'link-icon',         // Ionions 3 icon name string
 *   link: '/path/to/somewhere' // A string URL
 *   matcher: 'RegExp object'   // Route matching RegExp. Required if a path has URL parameters.
 *   options: { object }        // React router Route component options
 * }
 * ```
 *
 * Submenu:
 * ```
 * {
 *   title: 123,                // A string or translation id (Integer)
 *   icon: 'submenu-icon',      // Ionions 3 icon name string
 *   items: [{}, {}, ...]       // A list of link and/or submenu item objects
 * }
 * ```
 */
const MENU_ITEMS = [
  {
    title: "kayak",
    icon: "",
    items: [
      {
        title: "customer",
        icon: "ion-md-person",
        items: [
          {
            title: "customer",
            icon: "",
            link: "/customer",
            matcher: new RegExp("^/customer((/.*$)|$)"),
          },
          {
            title: "coDistribution",
            icon: "",
            link: "/co-distribution",
            matcher: new RegExp("^/co-distribution((/.*$)|$)"),
          },
          {
            title: "ownFeedbacks",
            icon: "",
            link: "/feedbacks/user",
          },
          {
            title: "ownCustomerContacts",
            icon: "",
            link: "/customer-contacts/user",
          },
        ],
      },
      {
        title: "readyReports",
        icon: "ion-md-paper",
        items: [
          {
            title: "search",
            icon: "",
            link: "/reports",
          },
        ],
      },
    ],
  },
];

const MENU_ITEMS_SWEDEN = [
  {
    title: "kayak",
    icon: "",
    items: [
      {
        title: "customer",
        icon: "ion-md-person",
        items: [
          {
            title: "customer",
            icon: "",
            link: "/customer",
            matcher: new RegExp("^/customer((/.*$)|$)"),
          },
          {
            title: "ownFeedbacks",
            icon: "",
            link: "/feedbacks/user",
          },
          {
            title: "ownCustomerContacts",
            icon: "",
            link: "/customer-contacts/user",
          },
        ],
      },
    ],
  },
];

const ADMIN_MENU_ITEMS = {
  title: "admin",
  icon: "ion-md-settings",
  items: [
    {
      title: "userManagement",
      icon: "",
      link: "/admin/user-management",
      matcher: new RegExp("^/admin/user-management((/.*$)|$)"),
    },
    {
      title: "parametersManagement",
      icon: "",
      link: "/admin/parameters-group-management",
      matcher: new RegExp("^/admin/parameters-group-management((/.*$)|$)"),
    },
    {
      title: "productManagement",
      icon: "",
      link: "/admin/product-management",
      matcher: new RegExp("^/admin/product-management((/.*$)|$)"),
    },
    {
      title: "priceListManagement",
      icon: "",
      link: "/admin/price-list-management",
      matcher: new RegExp("^/admin/price-list-management((/.*$)|$)"),
    },
    {
      title: "invoicingPlan",
      icon: "",
      link: "/admin/invoicing-plan",
      matcher: new RegExp("^/admin/price-list-management((/.*$)|$)"),
    },
    {
      title: "campaignManagement",
      icon: "",
      link: "/admin/campaign-management",
      matcher: new RegExp("^/admin/price-list-management((/.*$)|$)"),
    },
    {
      title: "digitalProductManagement",
      icon: "",
      link: "/admin/digital-product-management",
      matcher: new RegExp("^/admin/price-list-management((/.*$)|$)"),
    },
  ],
};

/**
 * This function goes through given menu structure and assigns necessary variables
 * (id, parent menus, types, and classNames). This is so that it's easier to define
 * the application menu by hand without needing to set every variable the menu needs
 * to function properly. This menu variable must be immutable.
 */
const initMenu = (data) => {
  // Assign id's and types for menu items
  let counter = 0;
  const loop = (items, parent) => {
    return items.map((item) => {
      counter += 1;
      if (item.items !== undefined) {
        return {
          ...item,
          id: counter,
          type: "menu",
          parent: parent,
          items: loop(item.items, counter),
        };
      } else {
        return { ...item, id: counter, type: "link", parent: parent };
      }
    });
  };

  const menu = loop(data, null);

  // CSS classes for menu items based on their level
  const classes = {
    0: "main-menu-item",
    1: "sub-menu-item",
    2: "sub-sub-menu-item",
    3: "sub-sub-sub-menu-item",
  };

  // Assign levels and classNames for menu items
  let level = 0;
  const iter = (itemArray) => {
    const arr = [];

    // eslint-disable-next-line array-callback-return
    itemArray.map((items) => {
      // eslint-disable-next-line array-callback-return
      items.map((item) => {
        item.level = level;
        item.className = classes[level] !== undefined ? classes[level] : "";

        if (item.type === "menu") {
          arr.push(item.items);
        }
      });
    });

    level += 1;
    if (arr.length > 0) {
      iter(arr);
    }
  };

  iter([menu]);

  return menu;
};

const initMenuAsAdmin = (data) => {
  // Clone array and objects recursively
  const menu = cloneDeep(data);

  menu[0].items.push(ADMIN_MENU_ITEMS);

  return initMenu(menu);
};

/**
 * Initialize a simpler menu state which is a flat object with id's as keys.
 * This object is used to track and change menu's state.
 */
const initMenuState = (menuItems) => {
  const state = {};
  const loop = (items) => {
    // eslint-disable-next-line array-callback-return
    items.map((item) => {
      if (item.type === "menu") {
        state[item.id] = { type: "menu", parent: item.parent, open: false };
        loop(item.items);
      } else {
        state[item.id] = {
          type: "link",
          parent: item.parent,
          link: item.link,
          matcher: item.matcher,
        };
      }
    });
  };

  loop(menuItems);

  return state;
};

class NavigationContainer extends React.Component {
  constructor(props) {
    super(props);

    const init = props.isAdmin ? initMenuAsAdmin : initMenu;
    if (Configuration.DEFAULT_SYSOPTION === "S") {
      this.menu = init(MENU_ITEMS_SWEDEN);
    } else {
      this.menu = init(MENU_ITEMS);
    }

    // Menu's state
    const menuState = initMenuState(this.menu);
    // Which submenus should be opened
    const openMenus = this._openMenus(props.location, menuState);

    this.state = {
      menu: { ...menuState, ...openMenus },
      visible: true,
      menuCollapsed: false,
    };

    this._menuClickHandler = this._menuClickHandler.bind(this);
    this._toggleCollapse = this._toggleCollapse.bind(this);
  }

  // Unused method
  // _toggleVisibility() {
  //   this.setState({ visible: !this.state.visible });
  // }

  _findMenuItemByRoute(route, menu) {
    for (const key in menu) {
      const item = menu[key];
      if (item.type === "link") {
        if (item.matcher !== undefined) {
          if (item.matcher.test(route)) {
            return key;
          }
        } else {
          if (item.link === route) {
            return key;
          }
        }
      }
    }
  }

  // Open submenus until the currently active route's link becomes visible.
  _openMenus(location, menu) {
    const id = this._findMenuItemByRoute(location.pathname, menu);
    const open = {};

    if (id !== undefined) {
      let parent = menu[id].parent;
      while (parent !== null) {
        open[parent] = { ...menu[parent], open: true };
        parent = menu[parent].parent;
      }
    }

    return open;
  }

  _menuClickHandler(id) {
    const target = id;
    const state = this.state.menu[target];
    const newState = { ...this.state.menu, [target]: { ...state, open: !state.open } };

    this.setState({ menu: newState });
  }

  _toggleCollapse() {
    this.setState({ menuCollapsed: !this.state.menuCollapsed });
  }

  componentDidUpdate(prevProps) {
    // Check whether route has changed and, if so, open menus so that the
    // current route's link is visible on the navigation bar.
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const open = this._openMenus(this.props.location, this.state.menu);
      this.setState({ menu: { ...this.state.menu, ...open } });
    }
  }

  render() {
    return (
      <div id="main-nav" className={this.state.menuCollapsed ? "menu-collapsed" : ""}>
        <i
          onClick={this._toggleCollapse}
          className={
            this.state.menuCollapsed ? "icon ion-ios-arrow-forward" : "icon ion-ios-arrow-back"
          }
        />
        {!this.state.menuCollapsed && (
          <MainMenu
            items={this.menu}
            state={this.state.menu}
            clickHandler={this._menuClickHandler}
          />
        )}
      </div>
    );
  }
}

NavigationContainer.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  // router props
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default NavigationContainer;
