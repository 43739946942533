import { push } from "connected-react-router";

import store from "@app/store.js";
import { setAuth } from "@app/user/user.action.js";
import { notify } from "@app/notification/notification.action.js";
import { tr } from "./translation.js";

/**
 * Helper class for communicating with the Ruby on Rails backend server.
 * Utilizes fetch() method and returns promises.
 */
class Fetcher {
  static _fetch(url, method, body = {}, hdr = {}, opts = {}) {
    // Additional options for Fetcher class
    const fetcherOpts = Object.assign(
      {
        returnRawResponse: false, // Whether to process fetch request before returning
      },
      opts
    );

    let init = { credentials: "include" };
    // By default use JSON and allow it to be overwritten
    const defaultHdr = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    // Go through given headers, if any, and set them. If value of type 'undefined'
    // is encountered, remove the key-value pair from the headers.
    const headers = { ...defaultHdr };
    for (const [key, value] of Object.entries(hdr)) {
      if (value === undefined) {
        delete headers[key];
      } else {
        headers[key] = value;
      }
    }

    switch (method) {
      case "POST":
        init = {
          ...init,
          method: "POST",
          headers: new Headers(headers),
          body: this._encodeBody(body, headers["Content-Type"]),
        };
        break;

      case "GET":
        init = { ...init, headers: new Headers(headers) };
        break;

      case "DELETE":
        init = {
          ...init,
          method: "DELETE",
          headers: new Headers(headers),
          body: this._encodeBody(body, headers["Content-Type"]),
        };
        break;

      case "PUT":
        init = {
          ...init,
          method: "PUT",
          headers: new Headers(headers),
          body: this._encodeBody(body, headers["Content-Type"]),
        };
        break;
    }

    if (fetcherOpts.returnRawResponse) {
      return fetch(url, init);
    }


    return fetch(url, init)
      .then((response) => {
        // Redirect to the login page if Unauthorized status is received
        if (response.status === 401) {
          store.dispatch(notify(tr("authenticationRequired"), "warning"));
          const data = {
            authenticated: false,
            denied_module_names: new Set([]),
            denied_paper_groups: [],
            denied_modules: [],
          };
          store.dispatch(setAuth(data));
          store.dispatch(push("/login"));
        }

        // Error or unexpected status code received, return an error.
        if (![200, 204].includes(response.status)) {
          // Try to read response as json and return it. If json parsing fails,
          // catch it and just return response status.
          return response
            .json()
            .then((error) => {
              let err = new Error();
              err = { status: response.status, error: error };
              throw err;
            })
            .catch((error) => {
              let err = new Error();
              err = { ...error, status: response.status };
              throw err;
            });
        }

        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  static _encodeBody(body, type) {
    switch (type) {
      case "application/json":
        return JSON.stringify(body);

      case "multipart/form-data":
      default:
        return body;
    }
  }

  // Return fetch response without processing it in any way
  static rawFetch(url, method, body = {}, hdr = {}) {
    const opts = { returnRawResponse: true };
    return this._fetch(url, method, body, hdr, opts);
  }

  // Fetch to POST given url and return response json
  static postJson(url, body, hdr = {}) {
    return this._fetch(url, "POST", body, hdr).then((response) => {
      return response.json();
    });
  }

  // Fetch GET to given url and return response json
  static getJson(url) {
    return this._fetch(url, "GET").then((response) => {
      return response.json();
    });
  }

  // Fetch PUT to given url and return response
  static putJson(url, body, hdr = {}) {
    return this._fetch(url, "PUT", body, hdr).then((response) => {
      return response.json();
    });
  }

  // Fetch DELETE to given url and return response
  static delete(url, body, hdr = {}) {
    return this._fetch(url, "DELETE", body, hdr);
  }

  // Fetch POST to given url and return response promise
  static post(url, body, hdr = {}) {
    return this._fetch(url, "POST", body, hdr);
  }

  // Fetch GET to given url and return response promise
  static get(url) {
    return this._fetch(url, "GET");
  }

  // Fetch PUT to given url and return response promise
  static put(url, body, hdr = {}) {
    return this._fetch(url, "PUT", body, hdr);
  }

  // Fetch POST multipart/form-data
  // Body needs to be 'FormData' object so that request headers are set correctly.
  static postFormData(url, formData, hdr = {}) {
    // Forcefully remove 'Content-Type' header and let the browser calculate it.
    // Header needs boundary values calculated and setting 'Content-Type' here
    // prevents the browser from filling that information automatically.
    const headers = { ...hdr, "Content-Type": undefined };
    return this._fetch(url, "POST", formData, headers);
  }
}

/** Wrapper for Fetcher which retries a request on error. */
const fetchWithRetry = (func, args, retries = 2) => {
  let i = 0;

  return new Promise((resolve, reject) => {
    const wrapped = () => {
      Fetcher[func](...args)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          // Retry only on server error
          if (error.status >= 500 && i < retries) {
            i += 1;
            // Wait a while before retrying
            setTimeout(wrapped, 250);
          } else {
            reject(error);
          }
        });
    };
    wrapped();
  });
};

export { Fetcher, fetchWithRetry };
