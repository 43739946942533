import { removeNullValues, resolveFieldValueFromObject } from "@utils/common.js";
import { Fetcher, fetchWithRetry } from "@utils/fetcher.js";
import apiUrl from "@utils/url.js";


export async function fetchAllInvoicingProgram() {
  const url = apiUrl(`invoice/monet_program`);

  return fetchWithRetry("getJson", [url])
    .then((InvoicingProgram = []) => {
      return InvoicingProgram;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchInvoicingProgram(invoicingProgram) {
  const url = apiUrl(`invoice/monet_program/${invoicingProgram.monetplanno}`);

  return fetchWithRetry("getJson", [url])
    .then((InvoicingProgram = []) => {
      return InvoicingProgram;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function postInvoicingProgram(InvoicingProgram) {
  const url = apiUrl(`invoice/monet_program`);
  let payload = InvoicingProgram;

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function putInvoicingProgram(InvoicingProgram) {
  const url = apiUrl(`invoice/monet_program`);
  let payload = InvoicingProgram;

  return Fetcher.put(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function deleteInvoicingProgram(InvoicingProgram) {
  const url = apiUrl(`invoice/monet_program/${InvoicingProgram.monetplanno}/${InvoicingProgram.progdate}`);

  return Fetcher.delete(url)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchInvoiceControl(invoiceControl) {
  const url = apiUrl(`invoice/plan_control/${invoiceControl.monetplanno}/${invoiceControl.invdate}`);

  return fetchWithRetry("getJson", [url])
    .then((InvoiceControl = []) => {
      return InvoiceControl;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchInvoiceClassHeading(invoicingProgram) {
  const url = apiUrl(`invoice/class_heading/${invoicingProgram.papercode}/${invoicingProgram.monetplanno}/${invoicingProgram.progdate}`);

  return fetchWithRetry("getJson", [url])
    .then((invClassHeading = []) => {
      return invClassHeading;

    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchInvoiceProvision(invoiceProvision) {
  const url = apiUrl(`invoice/feep_control/${invoiceProvision.monetplanno}/${invoiceProvision.invdate}`);

  return fetchWithRetry("getJson", [url])
    .then((invoiceProvision = []) => {
      return invoiceProvision;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchInvoiceSingleControl(invoiceSingleControl) {
  const url = apiUrl(`invoice/single_control/${invoiceSingleControl.monetplanno}/${invoiceSingleControl.invdate}`);

  return fetchWithRetry("getJson", [url])
    .then((InvoiceSingleControl = []) => {
      return InvoiceSingleControl;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchSingleCampaigns(singleInvoiceCampaigns) {
  const url = apiUrl(`invoice/inv_camp/${singleInvoiceCampaigns.monetplanno}/${singleInvoiceCampaigns.invdate}`);

  return fetchWithRetry("getJson", [url])
    .then((singleInvoiceCampaigns = []) => {
      return singleInvoiceCampaigns;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchInvoiceClassDetails(invoicingProgram) {
  const url = apiUrl(`invoice/class_details/${invoicingProgram.papercode}/${invoicingProgram.monetplanno}/${invoicingProgram.progdate}`);

  return fetchWithRetry("getJson", [url])
    .then((invClassDetails = []) => {
      return invClassDetails;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchFeeGroups() {
  const url = apiUrl(`invoice/fee_group`);

  return fetchWithRetry("getJson", [url])
    .then((feeGroups = []) => {
      return feeGroups;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchFeepGroups(params) {
  const url = apiUrl(`invoice/feep_group/${params.monetplanno}/${params.invdate || params.rundate}`);

  return fetchWithRetry("getJson", [url])
    .then((feeGroups = []) => {
      return feeGroups;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function fetchInvoiceReminder(params) {
  const url = apiUrl(`invoice/dun_control/${params.monetplanno}/${params.invdate}`);
  // const url = apiUrl(`invoice/feep_group/3/2023-12-21/S1`);

  return fetchWithRetry("getJson", [url])
    .then((reminder = []) => {
      return reminder;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function fetchInvoiceReminderPostcodes(params) {
  const url = apiUrl(`invoice/dunc_zip/${params.monetplanno}/${params.invdate}`);
  // const url = apiUrl(`invoice/feep_group/3/2023-12-21/S1`);

  return fetchWithRetry("getJson", [url])
    .then((reminder = []) => {
      return reminder;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function fetchInvoiceCollectionPostcodes(params) {
  console.log({ params })
  const url = apiUrl(`invoice/unp_zip/${params.monetplanno}/${params.invdate}`);
  // const url = apiUrl(`invoice/feep_group/3/2023-12-21/S1`);

  return fetchWithRetry("getJson", [url])
    .then((collection = []) => {
      return collection;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function fetchInvoiceCollection(params) {
  const url = apiUrl(`invoice/unp_control/${params.monetplanno}/${params.invdate}`);
  // const url = apiUrl(`invoice/feep_group/3/2023-12-21/S1`);

  return fetchWithRetry("getJson", [url])
    .then((collection = []) => {
      return collection;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function postInvoiceControl(invoiceControl) {
  const url = apiUrl(`invoice/plan_control`);
  let payload = invoiceControl;

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function postInvoiceProvision(invoiceProvision) {
  const url = apiUrl(`invoice/feep_control`);
  let payload = invoiceProvision;

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function postInvoiceSingleControl(invoiceSingleControl) {
  const url = apiUrl(`invoice/single_control`);
  let payload = invoiceSingleControl;

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function postInvoiceCampaigns(invoiceCampaigns) {
  const url = apiUrl(`invoice/inv_camp`);
  let payload = {invcamp: invoiceCampaigns};

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function updateInvoiceCampaigns(invoiceCampaigns) {
  const url = apiUrl(`invoice/inv_camp`);
  let payload = invoiceCampaigns;

  return Fetcher.put(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function postInvoiceFeeGroups(invoiceFeeGroups) {
  const url = apiUrl(`invoice/feep_group`);
  let payload = {invfeepgroup: [invoiceFeeGroups]};

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function updateInvoiceFeeGroups(invoiceFeeGroups) {
  const url = apiUrl(`invoice/feep_group`);
  let payload = invoiceFeeGroups;

  return Fetcher.put(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function postInvoiceReminder(invoiceReminder) {
  const url = apiUrl(`invoice/dun_control`);
  let payload = invoiceReminder;

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function postInvoiceReminderPostcodes(postcodes) {
  const url = apiUrl(`invoice/dunc_zip`);
  let payload = postcodes;

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function putInvoiceReminderPostcodes(postcodes) {
  const url = apiUrl(`invoice/dunc_zip`);
  let payload = postcodes;

  return Fetcher.put(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function postInvoiceCollectionPostcodes(postcodes) {
  const url = apiUrl(`invoice/unp_zip`);
  let payload = postcodes;

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function putInvoiceCollectionPostcodes(postcodes) {
  const url = apiUrl(`invoice/unp_zip`);
  let payload = postcodes;

  return Fetcher.put(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function deleteInvoiceReminderPostcode(postcode) {
  console.log('hereee')
  const url = apiUrl(`invoice/dunc_zip/${postcode.monetplanno}/${postcode.invdate}${postcode.countrycode && `/${postcode.countrycode}`}${postcode.startzipcode && `/${postcode.startzipcode}`}`);

  return Fetcher.delete(url)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function deleteInvoiceCollectionPostcode(postcode) {
  console.log('hereee')
  const url = apiUrl(`invoice/unp_zip/${postcode.monetplanno}/${postcode.invdate}${postcode.countrycode && `/${postcode.countrycode}`}${postcode.startzipcode && `/${postcode.startzipcode}`}`);

  return Fetcher.delete(url)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function postInvoiceCollection(invoiceCollection) {
  const url = apiUrl(`invoice/unp_control`);
  let payload = invoiceCollection;

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function postInvoiceReminderAreas(invoiceCollection) {
  const url = apiUrl(`invoice/dunc_zone`);
  let payload = invoiceCollection;

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function deleteInvoiceReminderArea(area) {
  console.log('hereee')
  const url = apiUrl(`invoice/dunc_zone/${area.monetplanno}/${area.invdate}${area.zonelevel && `/${area.zonelevel}`}${area.zoneno && `/${area.zoneno}`}`);

  return Fetcher.delete(url)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function deleteInvoiceCollectionArea(area) {
  console.log('hereee')
  const url = apiUrl(`invoice/unp_zone/${area.monetplanno}/${area.invdate}${area.zonelevel && `/${area.zonelevel}`}${area.zoneno && `/${area.zoneno}`}`);

  return Fetcher.delete(url)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchInvoiceReminderAreas(params) {
  const url = apiUrl(`invoice/dunc_zone/${params.monetplanno}/${params.invdate}`);
  // const url = apiUrl(`invoice/feep_group/3/2023-12-21/S1`);

  return fetchWithRetry("getJson", [url])
    .then((reminderAreas = []) => {
      return reminderAreas;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function postInvoiceCollectionAreas(invoiceCollection) {
  const url = apiUrl(`invoice/unp_zone`);
  let payload = invoiceCollection;

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchInvoiceCollectionAreas(params) {
  const url = apiUrl(`invoice/unp_zone/${params.monetplanno}/${params.invdate}`);
  // const url = apiUrl(`invoice/feep_group/3/2023-12-21/S1`);

  return fetchWithRetry("getJson", [url])
    .then((collectionAreas = []) => {
      return collectionAreas;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
