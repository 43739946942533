import { resolveFieldValueFromObject } from "@utils/common.js";
// import * as priceListActions from "./priceList/priceList.action";
// import * as priceGroupActions from "./priceGroup/priceGroup.action";
import * as campaignActions from "./campaign.action";


const defaultState = {
 
  invoicingProgram: [],
  invoicingProgramLoading: false,
  removingInvoicingProgram: false,
  selectedProgramRow: {},
  selectedCampaign: {},

  invoicingPlan: [],
  invoicingPlanLoading: false,
  removingInvoicingPlan: false,

  isSubInvoicesModalOpen: false,
  isCollectionModalOpen: false,
  isProvisionModalOpen: false,
  isReminderModalOpen: false,
  isSingleModalOpen: false,
  isAddEditProgramModalOpen: false,
  isInvoiceSumModalOpen: false,
  invoiceSumLoading:false,
  invoiceSum:{invClassHeading:{}, invClassDetails:[]},
  isCampaignModalOpen: false,



};

const campaignReducer = (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    case campaignActions.ADMIN_IS_LOADING_ALL_CAMPAIGNS: {
      return { ...state, campaignsList: [], campaignsListLoading: true };
    }
    case campaignActions.ADMIN_SET_ALL_CAMPAIGNS: {
        return { ...state, campaignsList: payload[0].table, campaignsListLoading: false };
      }
    case campaignActions.ADMIN_SET_CAMPAIGNS: {
        return { ...state, packageCampaigns: payload && payload.length ? payload[0].findcampaign : [] };
      }
    // case campaignActions.ADMIN_CAMPAIGN_EDITED: {
    //     return { ...state, campaignsList: payload[0].table };
    //   }
    case campaignActions.ADMIN_SET_ALL_CAMPAIGN_GROUPS: {
        return { ...state, campaignGroups: payload[0].campgroup };
      }
    case campaignActions.ADMIN_SET_ALL_PRODUCT_PACKAGES: {
        return { ...state, productPackages: payload, loadingProductPackages: false };
      }
    case campaignActions.ADMIN_SET_ALL_COPRODUCTS: {
        return { ...state, coproducts: payload };
      }
    case campaignActions.ADMIN_IS_LOADING_ALL_PRODUCT_PACKAGES: {
        return { ...state, productPackages: [], loadingProductPackages: true };
      }
    case campaignActions.ADMIN_SET_ALL_PAPERS: {
        return { ...state, papers: payload };
      }
    case campaignActions.ADMIN_LOADING_SEARCH_CAMPAIGNS: {
        return { ...state, campaignsList: payload, campaignsListLoading: true };
      }
    case campaignActions.ADMIN_SET_SEARCH_CAMPAIGNS: {
        return { ...state, campaignsList: payload && payload.length ? payload[0].findcampaign : [], campaignsListLoading: false, searchParams: payload[1].params };
      }
    case campaignActions.ADMIN_SET_SEARCH_PACKAGE_CAMPAIGNS: {
        return { ...state, packageCampaignsList: payload && payload.length ? payload[0].findcampaign.length ? state.packageCampaignsList ? {...state.packageCampaignsList, [payload[0].findcampaign[0].packageid]: payload[0].findcampaign} : {[payload[0].findcampaign[0].packageid]: payload[0].findcampaign} : {} : {}, campaignsListLoading: false, packageCampaignsListLoading: false };
      }
    case campaignActions.ADMIN_SET_SINGLE_PACKAGE_PRODUCT: {
        return { ...state, packageProduct: payload.campaigns };
      }
    case campaignActions.ADMIN_SET_SINGLE_CAMPAIGN: {
        return { ...state, singleCampaign: payload };
      }
    case campaignActions.ADMIN_IS_LOADING_ALL_GOODS_AND_SERVICES: {
      return { ...state, goodsServices: [], goodsServicesLoading: true };
    }
    case campaignActions.ADMIN_SET_SEARCH_PACKAGE_CAMPAIGNS_LOADING: {
      return { ...state, packageCampaignsListLoading: true };
    }
    case campaignActions.ADMIN_SET_ALL_GOODS_AND_SERVICES: {
        return { ...state, goodsServices: payload ? payload.length ? payload : payload.data ? payload.data[0]['coproduct a, coprodrow b, campcoprod c'] : [] : [], goodsServicesLoading: false };
      }
    case campaignActions.ADMIN_IS_LOADING_ALL_PREVIOUS_CAMPAIGNS: {
      return { ...state, previousCampaignsList: [], previousCampaignsListLoading: true };
    }
    case campaignActions.ADMIN_SET_ALL_PREVIOUS_CAMPAIGNS: {
        return { ...state, previousCampaignsList: payload ? payload.length ? payload : payload.data ? payload.data[0]["table"] : [] : [], previousCampaignsListLoading: false };
      }
    case campaignActions.SELECT_CAMPAIGN_ROW: {
      return { ...state, selectedCampaign: {...payload.original, isEdit: payload.isEdit} };
    }
    case campaignActions.SELECT_PACKAGE_CAMPAIGN_ROW: {
      return { ...state, selectedPackageCampaign: {...payload.original, isEdit: payload.isEdit} };
    }

    case campaignActions.TOGGLE_ADD_EDIT_CAMPAIGN_MODAL: {
      return { ...state, isCampaignModalOpen: !state.isCampaignModalOpen };
    }
    case campaignActions.TOGGLE_ADD_EDIT_GOODS_AND_SERVICES: {
      return { ...state, goodsServicesModalOpen: !state.goodsServicesModalOpen };
    }
    case campaignActions.ADMIN_SET_REMOVING_CAMPAIGN: {
      return { ...state, removingCampaign: payload, campaignRemoved: false };
    }
    case campaignActions.ADMIN_CAMPAIGN_REMOVED: {
      return { ...state, removingCampaign: false, campaignRemoved: true };
    }
    case campaignActions.ADMIN_SET_REMOVING_CAMPAIGN_COPRODUCT: {
      return { ...state, removingCoprod: payload, coproductRemoved: false };
    }
    case campaignActions.ADMIN_CAMPAIGN_COPRODUCT_REMOVED: {
      return { ...state, removingCoprod: false, coproeuctRemoved: true };
    }
    case campaignActions.ADMIN_CLEAR_PREVIOUS_CAMPAIGNS: {
      return { ...state, previousCampaignsList: [], singleCampaign: null };
    }
   
    default:
      return state;
  }
};

export default campaignReducer;
