import { resolveFieldValueFromObject } from "@utils/common.js";
import * as priceListActions from "./priceList/priceList.action";
import * as priceGroupActions from "./priceGroup/priceGroup.action";
import * as subsTypeActions from "./subsType/subsType.action";
import * as priceRowActions from "./priceRow/priceRow.action";

let actions = { ...priceListActions, ...priceGroupActions, ...subsTypeActions, ...priceRowActions };

const defaultState = {
  priceList: [],
  priceListLoading: false,
  removingPriceList: false,
  priceGroup: [],
  priceGroupsLoading: false,
  removingPriceGroup: false,
  subsType: [],
  subsTypeLoading: false,
  removingSubsType: false,
  priceRows: [],
  priceRowsLoading: false,
  removingPriceRow: false,
  periodicPriceRows: [],
  periodicPriceRowsLoading: false,
};

const adminReducer = (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {

    case actions.ADMIN_PRICE_IS_LOADING_ALL_PRICE_LIST: {
      return { ...state, priceList: [], priceListLoading: true };
    }
    case actions.ADMIN_PRICE_SET_ALL_PRICE_LIST: {
      console.log({state, payload})
      return { ...state, priceList: payload.map(p=> ({...p, pricestartdate: p.pricestartdate.slice(0,10), pricegroupname: state.priceGroup && state.priceGroup.length && state.priceGroup.find(pg=>pg.pricegroup == p.pricegroup) ? state.priceGroup.find(pg=>pg.pricegroup == p.pricegroup).pricegroupname : p.pricegroupname}) ), priceListLoading: false };
    }

    case actions.ADMIN_PRICE_PRICE_LIST_EDITED: {
      const priceList = state.priceList.map((p) => {
        if (p.pricelistno === payload.pricelistno) {
          return {
            ...payload,
            papercode: resolveFieldValueFromObject(payload.papercode),
            packageid: resolveFieldValueFromObject(payload.packageid),
            pricegroup: resolveFieldValueFromObject(payload.pricegroup),
            pricingtype: resolveFieldValueFromObject(payload.pricingtype),
            stand_itemqty: payload.stand_itemqty,
            per_itemqty: payload.per_itemqty,
            pricestartdate: payload.pricestartdate,
            priceenddate: payload.priceenddate,
            vatincluded: payload.vatincluded,
          }
        }
        return p;
      });
      return { ...state, priceList };
    }

    case actions.ADMIN_PRICE_SET_REMOVING_PRICE_LIST: {
      return { ...state, removingPriceList: payload || false };
    }

    case actions.ADMIN_PRICE_PRICE_LIST_REMOVED: {
      const priceList = state.priceList.filter((p) => p.pricelistno !== payload.pricelistno);
      return { ...state, priceList };
    }


    case actions.ADMIN_PRICE_IS_LOADING_ALL_PRICE_GROUPS: {
      return { ...state, priceGroup: [], priceGroupsLoading: true };
    }
    case actions.ADMIN_PRICE_SET_ALL_PRICE_GROUPS: {
      return { ...state, priceGroup: payload, priceList: state.priceList && state.priceList.length ? state.priceList.map(p=> ({...p, pricestartdate: p.pricestartdate.slice(0,10), pricegroupname: payload && payload.length && payload.find(pg=>pg.pricegroup == p.pricegroup) ? state.priceGroup.find(pg=>pg.pricegroup == p.pricegroup).pricegroupname : p.pricegroupname}) ) : undefined, priceGroupsLoading: false };
    }

    case actions.ADMIN_PRICE_PRICE_GROUP_EDITED: {
      return { ...state };

    }

    case actions.ADMIN_PRICE_SET_REMOVING_PRICE_GROUP: {
      return { ...state, removingPriceGroup: payload || false };
    }

    case actions.ADMIN_PRICE_PRICE_GROUP_REMOVED: {
      const priceGroup = state.priceGroup.filter((p) => p.pricelistno !== payload.pricelistno);
      return { ...state, priceGroup };
    }

    case actions.ADMIN_PRICE_IS_LOADING_ALL_SUBS_TYPE: {
      return { ...state, subsType: [], subsTypeLoading: true };
    }
    case actions.ADMIN_PRICE_SET_ALL_SUBS_TYPE: {
      return { ...state, subsType: payload, subsTypeLoading: false };
    }

    case actions.ADMIN_PRICE_SUBS_TYPE_EDITED: {
      let updatedSubsTypes = JSON.parse(JSON.stringify(state.subsType))
      let foundSubsType = updatedSubsTypes.findIndex(sub => (sub.papercode == payload.papercode) && (sub.substype_code == payload.substype_code));
      updatedSubsTypes[foundSubsType] = payload;
      return { ...state, subsType: updatedSubsTypes };
    }

    case actions.ADMIN_PRICE_SET_REMOVING_SUBS_TYPE: {
      return { ...state, removingSubsType: payload || false };
    }

    case actions.ADMIN_PRICE_SUBS_TYPE_REMOVED: {
      const index = state.subsType.findIndex((p) => (p.substype_code == payload.substype_code) && (p.papercode == payload.papercode));
      const subsType = JSON.parse(JSON.stringify(state.subsType))
      subsType.splice(index, 1)
      return { ...state, subsType };
    }

    case actions.ADMIN_PRICE_IS_LOADING_ALL_PRICE_ROW: {
      return { ...state, priceRows: [], priceRowsLoading: true };
    }
    case actions.ADMIN_PRICE_SET_ALL_PRICE_ROW: {
      return { ...state, priceRows: payload, priceRowsLoading: false };
    }

    case actions.ADMIN_PRICE_IS_LOADING_ALL_PERIODIC_PRICE_ROW: {
      return { ...state, periodicPriceRows: [], periodicPriceRowsLoading: true };
    }
    case actions.ADMIN_PRICE_SET_ALL_PERIODIC_PRICE_ROW: {
      return { ...state, periodicPriceRows: payload, periodicPriceRowsLoading: false };
    }

    case actions.ADMIN_PRICE_PRICE_ROW_EDITED: {
      const priceRows = state.priceRows.map((p) => {
        if (p.pricelistno === payload.pricelistno) {
          return {
            ...payload,
            papercode: resolveFieldValueFromObject(payload.papercode),
            packageid: resolveFieldValueFromObject(payload.packageid),
            pricegroup: resolveFieldValueFromObject(payload.pricegroup),
            pricingtype: resolveFieldValueFromObject(payload.pricingtype),
            stand_itemqty: payload.stand_itemqty,
            per_itemqty: payload.per_itemqty,
            pricestartdate: payload.pricestartdate,
            priceenddate: payload.priceenddate,
            vatincluded: payload.vatincluded,
          }
        }
        return p;
      });
      return { ...state, priceRows };
    }

    case actions.ADMIN_PRICE_SET_REMOVING_PRICE_ROW: {
      return { ...state, removingPriceRow: payload || false };
    }

    case actions.ADMIN_PRICE_PRICE_ROW_REMOVED: {
      const priceRows = state.priceRows.filter((p) =>
        !(p.pricelistno == payload.pricelistno &&
          p.papercode == payload.papercode &&
          p.productno == payload.productno &&
          p.subskind == payload.subskind &&
          p.period_mm == payload.period_mm &&
          p.issueqty == payload.issueqty)
      );
      return { ...state, priceRows };
    }

    default:
      return state;
  }
};

export default adminReducer;
