import * as actionCreator from "@utils/actionCreator.js";
import * as effect from "./user.effect";
import { notify } from "@app/notification/notification.action.js";
import { tr } from "@utils/translation.js";
import {RESET_STORE} from "../../actions/actionTypes.js"

const SLICE = "user";

export const SET_AUTHENTICATED = `${SLICE}/SET_AUTHENTICATED`;
export const setAuth = (data) => {
  const payload = {
    authenticated: data.authenticated,
    isAdmin: (data.groups && data.groups.includes("ADMIN")) || false,
    DENIED_MODULE_NAMES: data.denied_module_names,
    DENIED_PAPER_GROUPS: data.denied_paper_groups,
    DENIED_MODULES: data.denied_modules,
  };

  return actionCreator.createAction(SET_AUTHENTICATED, payload);
};

export const SET_USER_INFO = `${SLICE}/SET_USER_INFO`;
export const setUserInfo = (values) => {
  return actionCreator.createAction(SET_USER_INFO, { ...values });
};

export const SET_USER_SETTINGS = `${SLICE}/SET_USER_SETTINGS`;
export const setUserSettings = (settings) => {
  return actionCreator.createAction(SET_USER_SETTINGS, settings);
};

export const CHANGE_PUBLISHER = `${SLICE}/CHANGE_PUBLISHER`;
export const changePublisher = (publisher) => {
  return actionCreator.createAction(CHANGE_PUBLISHER, publisher);
};

export const SET_PUBLISHERS = `${SLICE}/SET_PUBLISHERS`;
export const setPublishers = (publishers, current) => {
  return actionCreator.createAction(SET_PUBLISHERS, { publishers, current });
};

export const SET_TABLE_COLUMN_VISIBILITY = `${SLICE}/SET_TABLE_COLUMN_VISIBILITY`;
export const setTableColumnVisibility = (tableName, hiddenColumns) => {
  return actionCreator.createAction(SET_TABLE_COLUMN_VISIBILITY, { tableName, hiddenColumns });
};

export const SET_USER_DEFAULTS = `${SLICE}/SET_USER_DEFAULTS`;
export const getUserDefaults = () => async (dispatch) => {
  const data = await effect.fetchUserDefaults();

  if (!data.error) {
    return dispatch(actionCreator.createAction(SET_USER_DEFAULTS, data));
  }

  dispatch(actionCreator.createAction(SET_USER_DEFAULTS, {}));
  dispatch(notify(tr("dbConnectFailure"), "error"));
};

export const SET_PARROW_USER_SETTINGS = `${SLICE}/SET_PARROW_USER_SETTINGS`;
export const getParrowUserSettings = (user) => async (dispatch) => {
  const parrowUserSettings = { invoiceLimit: 60 };

  const data = await effect.fetchParrowUserSettings();

  if (!data.error) {
    data.forEach((item) => {
      if (item.codeno === user) {
        const temp = item.codevalue.split(";");
        if (temp.length >= 3) parrowUserSettings.invoiceLimit = parseInt(temp[2]);
      }
    });

    return dispatch(actionCreator.createAction(SET_PARROW_USER_SETTINGS, parrowUserSettings));
  }

  dispatch(actionCreator.createAction(SET_PARROW_USER_SETTINGS, parrowUserSettings));
  dispatch(notify(tr("dbConnectFailure"), "error"));
};

export const SET_PAPER_CODES = `${SLICE}/SET_PAPER_CODES`;
export const setPaperCodes = (paperCodes) => {
  return actionCreator.createAction(SET_PAPER_CODES, paperCodes);
};

// Build 4.1.1.0 (ERP #12017)
export const SET_FORCE_RESET_PASSWORD = `${SLICE}/SET_FORCE_RESET_PASSWORD`;
export const setForceResetPassword = (forceResetPassword) => {
  return actionCreator.createAction(SET_FORCE_RESET_PASSWORD, forceResetPassword);
};

export const resetStore = () => ({
  type: RESET_STORE,
});

export const SET_ABORT_ALL_API_CALLS = `${SLICE}/SET_ABORT_ALL_API_CALLS`;
export const setAbortAllApiCalls = (abortAllApiCalls) => {
  return actionCreator.createAction(SET_ABORT_ALL_API_CALLS, abortAllApiCalls);
};