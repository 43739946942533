import { removeNullValues, resolveFieldValueFromObject } from "@utils/common.js";
import { Fetcher, fetchWithRetry } from "@utils/fetcher.js";
import apiUrl from "@utils/url.js";


export async function fetchInvoicingPlan() {
  const url = apiUrl(`invoice/monet_plan`);

  return fetchWithRetry("getJson", [url])
    .then((InvoicingPlan = []) => {
      return InvoicingPlan;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function postInvoicingPlan(InvoicingPlan) {
  const url = apiUrl(`invoice/monet_plan`);
  let payload = InvoicingPlan;

  return Fetcher.post(url, payload)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function postInvoiceCollect(invoiceCollect) {
  const url = apiUrl(`invoice/coll_control`);
  let payload = invoiceCollect;

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function putInvoicingPlan(InvoicingPlan) {
  const url = apiUrl(`invoice/monet_plan`);
  let payload = InvoicingPlan;

  return Fetcher.put(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function deleteInvoicingPlan(InvoicingPlan) {
  const url = apiUrl(`invoice/monet_plan/${InvoicingPlan.monetplanno}`);

  return Fetcher.delete(url)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchCampaigns(papercode) {
  const url = apiUrl(`campaigns/campaign/${papercode}?invtype=02`);

  return fetchWithRetry("getJson", [url])
    .then((campaigns = []) => {
      return campaigns;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchInvoiceCollect(invoiceParams) {
  const url = apiUrl(`invoice/coll_control/${invoiceParams.monetplanno}/${invoiceParams.invdate}`);

  return fetchWithRetry("getJson", [url])
    .then((collect = []) => {
      return collect;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function fetchAllAreas(program) {
  const url = apiUrl(`area/${program.papercode}/ /${program.areatype}`);

  return fetchWithRetry("getJson", [url])
    .then((areas = []) => {
      return areas;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function fetchAllCommupaps() {
  const url = apiUrl(`invoice/commupap`);

  return fetchWithRetry("getJson", [url])
    .then((commupap = []) => {
      return commupap;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}