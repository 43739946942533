import * as actions from "../actions/actionTypes.js";
import { sortObjects } from "../utils/validate.js";

const defaultState = {
  search: {
    addCustomerModalOpen: false,
    advancedSearchModalOpen: false,
    isSearchingCustomer: false,
    result: [],
    searchExpanded: false,
    selected: "",
  },
  subscriptionsAndProducts: {
    combined: [],
    selected: "",
    selectedObject: {},
  },
  recentCustomers: [],
  selectedCustomer: {},
  shouldClearCustomer: false,
  shouldAskCreatingNewSubs: false,
  processing: false,
  extraProduct: {
    subscriptionGiftModalOpen: false,
    extraProducts: [], // Customer's extra products
    selectedProduct: [], // Selected customer's extra product (an array of instalments)
    selectedInstalment: {}, // Selected extra product's selected instalment
    allowInstalmentDelete: false, // Whether the selected instalment can be deleted
  },
  subscriptionTypes: [], // subscriptionTypes are used "outside" subscription entity and so don't need to be "inside"
  subscription: {
    selectedSubscription: {},
    // #30344, latest created subs should be selected after closing newsubscription modal
    nextSelectedSubscription: {},
    subscriptions: [], // Customer's subscriptions
    subscriptionsRaw: [],
    allPapers: [],
    allMainPapers: [],
    allMainPapersWithPaperAll: [],
    selectedPaper: {
      papercode: undefined,
      papername: undefined,
    },
    selectedExtraProduct: {},
    availableExtraProducts: [], // Available extra products
    productPackage: [],
    selectedProductPackage: {},
    campaigns: [],
    filteredCampaigns: [],
    selectedCampaign: {},
    selectedSubsCampaignDetails: {}, // Build 3.6.4.1 (Issue #33098)
    campaignCoProducts: [],
    campaignCoProductsBySubscription: [],
    subscriptionTypes: [],
    highlightedSubscriptionProduct: "",
    subscriptionProducts: [],
    selectedSubscriptionProduct: {},
    communeCode: undefined,
    shouldReloadSubscriptions: false,
    cancelReasons: {},
    submitResult: {
      successfulSubmits: [],
      unsuccessfulSubmits: [],
    },
    availableDiscounts: [],
    allAvailableDiscounts: {},
    priceGroups: [],
    singleCopyPriceGroups: [], // Build 3.6.4.2 (Issue #32834)
    singleSubsPeriods: [], // Build 3.6.4.2 (Issue #32834)
    monthLengths: [],
    sameAddressSubscriptions: {
      modalOpen: false,
      subscriptions: [],
    },
    payer: {},
    recommender: {},
    salesman: {},
    invoiceModes: [],
    defaultInvoiceMode: {},
    freeSubsGroup: [],
    subsTempAddresses: [],
    allTempAddresses: [],
    resetHolidayAddress: false,
    subsSplitAddresses: [],
    resetSplitAddress: false,
    currentBasicAddress: {},
    currentInvoiceAddress: {},
    pendingInvoiceAddress: {},
    itemExpenseWithoutVat: 0,
    itemExpenseWithVat: 0,
    grossExclVat: 0,
    grossInclVat: 0,
    subscriptionPaperProduct: {},
    standItemQty: ["1"],
    perItemQty: ["1"],
    paperGroups: [],
    groupPapers: {},
    updatedInstalments: [],
    originalInstalments: [],
    selectedSubsInstalment: {},
    subscriptionValidInvModes: [], // Build 4.1.2.0 (ERP #12421)
  },
  cancelSubscription: {
    paidItems: [],
    openItems: [],
    selectedItem: {},
  },
  sleeps: {
    selectedSleep: {},
    sleeps: [],
  },
  events: {
    id: null,
    events: {},
    selected: null,
    changed: false, // Build 3.6.3.4 (Issue #32668)
  },
  salesman: {
    salesGroupPapers: [],
    salesGroups: {},
    salesFeeGroups: {},
    salesPrizes: {},
  },
  deliveryIssues: [],
  pendingAddress: undefined,
  pendingAddresses: [],
  updateCustomerModalOpen: false,
  // #30373, show the first marketingPermission with some fields denied
  marketingPermissions: [],
  // #27610
  displayCustomerConfirmModal: false,
  changingCusProperties: false, // Build 3.6.2.2 (Issue #31348)
  preferInvmodes: [], // Build 3.6.2.2 (Issue #31348)
  invmodePaperList: [], // Build 3.6.2.2 (Issue #31348)
  marketingMaterials: [], // Build 3.6.4.2 (Issue #33372)
  historyCustomerNo: 0, // Build 3.4.6.2 (Issue #33409)
  winsaleTypes: [], // Build 3.6.4.2 (Issue #26462)
  mainPaper: "", // Build 3.6.4.2 (Issue #12471)
  rowLength: -1, // Build 3.6.4.2 (Issue #12471)

  moduleRights: [], // (Issue #10814)
};

// Find an object from combined subscriptions and products array
const findCombinedProduct = (items, id) => {
  for (const item of items) {
    // eslint-disable-next-line no-prototype-builtins
    if (item.hasOwnProperty("expand")) {
      const result = findCombinedProduct(item.expand, id);
      if (result !== undefined) {
        return result;
      }
    }

    if (item.rowId === id) {
      return item;
    }
  }
};

const customerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.CUSTOMER_PROCESSING:
      return { ...state, processing: action.payload };

    case actions.CLEAR_CUSTOMER: {
      return {
        ...state,
        selectedCustomer: defaultState.selectedCustomer,
        shouldClearCustomer: defaultState.shouldClearCustomer,
      };
    }

    /**
     * Search components
     */
    case actions.SET_IS_SEARCHING_CUSTOMER: {
      const search = { ...state.search, isSearchingCustomer: action.payload };
      return { ...state, search };
    }

    case actions.TOGGLE_CUSTOMER_ADVANCED_SEARCH_MODAL: {
      const search = { ...state.search, advancedSearchModalOpen: action.payload };
      return { ...state, search };
    }

    case actions.SET_SEARCH_EXPANDED: {
      const search = { ...state.search, searchExpanded: action.payload };
      return { ...state, search };
    }

    case actions.SET_CUSTOMER_SEARCH_RESULT: {
      const search = { ...state.search, result: action.payload };
      return { ...state, search };
    }

    case actions.SELECT_SEARCH_RESULT: {
      const search = { ...state.search, selected: action.payload };
      return { ...state, search };
    }

    case actions.TOGGLE_ADD_CUSTOMER_MODAL: {
      const search = { ...state.search, addCustomerModalOpen: !state.search.addCustomerModalOpen };
      return { ...state, search };
    }

    /** */

    case actions.SET_SUBSCRIPTIONS_AND_PRODUCTS: {
      const subscriptionsAndProducts = {
        ...state.subscriptionsAndProducts,
        combined: action.combined,
      };
      return { ...state, subscriptionsAndProducts };
    }

    case actions.SELECT_SUBSCRIPTIONS_AND_PRODUCTS_ROW: {
      const combined = state.subscriptionsAndProducts.combined;
      const selectedObject = findCombinedProduct(combined, action.id) || {};
      const { expand, ...values } = selectedObject;

      const subscriptionsAndProducts = {
        ...state.subscriptionsAndProducts,
        selectedObject: values,
        selected: action.id,
      };
      return { ...state, subscriptionsAndProducts };
    }

    case actions.UPDATE_RECENT_CUSTOMERS: {
      return { ...state, recentCustomers: action.customers };
    }

    case actions.LOAD_CUSTOMER: {
      const customer = action.customer;
      return { ...state, selectedCustomer: customer };
    }

    case actions.LOAD_ORIGINAL_PINCODE: {
      return {
        ...state,
        selectedCustomer: { ...state.selectedCustomer, wwwpincodeorg: action.wwwpincode },
      };
    }

    case actions.TOGGLE_UPDATE_CUSTOMER_MODAL: {
      return { ...state, updateCustomerModalOpen: !state.updateCustomerModalOpen };
    }

    case actions.SHOULD_CLEAR_CUSTOMER: {
      return { ...state, shouldClearCustomer: action.flag };
    }

    case actions.LOAD_SUBSCRIPTIONS: {
      const subscription = { ...state.subscription, subscriptions: action.subscriptions };
      return { ...state, subscription };
    }

    case actions.LOAD_RAW_SUBSCRIPTIONS: {
      const subscription = { ...state.subscription, subscriptionsRaw: action.subscriptions };
      return { ...state, subscription };
    }

    case actions.CLEAR_SUBSCRIPTIONS: {
      // Clear subscription data but retain data which is not related to one
      // specific subscription.
      const {
        cancelReasons,
        submittedSubscriptions,
        allAvailableDiscounts,
        allMainPapers,
        allMainPapersWithPaperAll,
        allPapers,
        invoiceModes, // #29857, missing invmodes makes newsubscription modal loading infinitely
        nextSelectedSubscription, // #31655, need to keep current selected subscription
        subscriptionValidInvModes, // Build 4.1.2.0 (ERP #12421)
      } = state.subscription;
      const subscription = {
        ...defaultState.subscription,
        cancelReasons,
        submittedSubscriptions,
        allAvailableDiscounts,
        allMainPapers,
        allMainPapersWithPaperAll,
        allPapers,
        invoiceModes, // #29857, missing invmodes makes newsubscription modal loading infinitely
        nextSelectedSubscription, // #31655, need to keep current selected subscription
        subscriptionValidInvModes, // Build 4.1.2.0 (ERP #12421)
      };
      return { ...state, subscription };
    }

    case actions.SELECT_SUBSCRIPTION: {
      const subscription = { ...state.subscription, selectedSubscription: action.subscription };
      return { ...state, subscription };
    }

    // #30344, latest created subs should be selected after closing newsubscription modal
    case actions.NEXT_SELECT_SUBSCRIPTION: {
      const subscription = { ...state.subscription, nextSelectedSubscription: action.subscription };
      return { ...state, subscription };
    }

    case actions.LOAD_CUSTOMER_EXTRA_PRODUCTS: {
      const extraProduct = { ...state.extraProduct, extraProducts: action.extraProducts };
      return { ...state, extraProduct };
    }

    case actions.SELECT_CUSTOMER_EXTRA_PRODUCT: {
      const extraProduct = { ...state.extraProduct, selectedProduct: action.extraProduct };
      return { ...state, extraProduct };
    }

    case actions.SELECT_CUSTOMER_EXTRA_PRODUCT_INSTALMENT: {
      const instalment = action.instalment;
      const allowDelete = !(
        (instalment.invno && instalment.invno !== "0") ||
        instalment.st_printed === "Y"
      );
      const extraProduct = {
        ...state.extraProduct,
        selectedInstalment: instalment,
        allowInstalmentDelete: allowDelete,
      };
      return { ...state, extraProduct };
    }

    case actions.CLEAR_CUSTOMER_EXTRA_PRODUCTS: {
      return { ...state, extraProduct: { ...defaultState.extraProduct } };
    }

    case actions.LOAD_CURRENT_BASIC_ADDRESS: {
      const subscription = { ...state.subscription, currentBasicAddress: action.address };
      return { ...state, subscription };
    }

    case actions.LOAD_PENDING_ADDRESS_CHANGE: {
      return { ...state, pendingAddress: action.pendingAddress };
    }

    // Build 3.6.4.2 (ERP #11199)
    case actions.LOAD_PENDING_ADDRESSES_CHANGE: {
      return {
        ...state,
        pendingAddresses: action.pendingAddresses,
        pendingAddress: action.pendingAddress,
      };
    }

    case actions.LOAD_SUBS_TEMP_ADDRESSES: {
      const subscription = { ...state.subscription, subsTempAddresses: action.subsTempAddresses };
      return { ...state, subscription };
    }

    case actions.LOAD_ALL_TEMP_ADDRESSES: {
      const subscription = { ...state.subscription, allTempAddresses: action.allTempAddresses };
      return { ...state, subscription };
    }

    case actions.RESET_HOLIDAY_ADDRESS: {
      const subscription = { ...state.subscription, resetHolidayAddress: action.flag };
      return { ...state, subscription };
    }

    case actions.LOAD_SUBS_SPLIT_ADDRESSES: {
      const subscription = { ...state.subscription, subsSplitAddresses: action.subsSplitAddresses };
      return { ...state, subscription };
    }

    case actions.RESET_SPLIT_ADDRESS: {
      const subscription = { ...state.subscription, resetSplitAddress: action.flag };
      return { ...state, subscription };
    }

    case actions.LOAD_CURRENT_INVOICE_ADDRESS: {
      const subscription = {
        ...state.subscription,
        currentInvoiceAddress: action.currentInvoiceAddress,
      };
      return { ...state, subscription };
    }

    case actions.LOAD_PENDING_INVOICE_ADDRESS: {
      const subscription = {
        ...state.subscription,
        pendingInvoiceAddress: action.pendingInvoiceAddress,
      };
      return { ...state, subscription };
    }

    case actions.LOAD_PAPERS: {
      const subscription = {
        ...state.subscription,
        allPapers: action.allPapers,
        allMainPapers: action.allMainPapers,
        allMainPapersWithPaperAll: action.allMainPapersWithPaperAll,
      };
      return { ...state, mainPaper: action.mainPaper, subscription };
    }

    case actions.LOAD_AVAILABLE_EXTRA_PRODUCTS: {
      const subscription = {
        ...state.subscription,
        availableExtraProducts: action.extraProducts,
      };
      return { ...state, subscription };
    }

    case actions.CLEAR_SUBSCRIPTION_EXTRA_PRODUCTS: {
      const subscription = {
        ...state.subscription,
        subscriptionProducts: action.subscriptionProducts,
      };
      return { ...state, subscription };
    }

    case actions.SET_SELECTED_PAPER: {
      const subscription = {
        ...state.subscription,
        selectedPaper: action.selectedPaper,
      };
      return { ...state, subscription };
    }

    case actions.SET_PRODUCTPACKAGE: {
      const subscription = {
        ...state.subscription,
        productPackage: action.productPackage,
      };
      return { ...state, subscription };
    }

    case actions.SET_SELECTED_EXTRA_PRODUCT: {
      const subscription = {
        ...state.subscription,
        selectedExtraProduct: {
          ...action.extraProduct,
          modifiedUnitPrice: action.extraProduct.modifiedUnitPrice || action.extraProduct.price,
          pcs: action.extraProduct.pcs || 1, // By default set pcs to one
        },
      };
      return { ...state, subscription };
    }

    case actions.RESET_SELECTED_PAPER: {
      const subscription = {
        ...state.subscription,
        selectedPaper: defaultState.subscription.selectedPaper,
      };
      return { ...state, subscription };
    }

    case actions.RESET_SELECTED_EXTRA_PRODUCT: {
      const subscription = {
        ...state.subscription,
        selectedExtraProduct: defaultState.subscription.selectedExtraProduct,
      };
      return { ...state, subscription };
    }

    case actions.SET_SELECTED_PRODUCT_PACKAGE: {
      const subscription = {
        ...state.subscription,
        selectedProductPackage: action.selectedProductPackage,
      };
      return { ...state, subscription };
    }

    case actions.RESET_SELECTED_PRODUCT_PACKAGE: {
      const subscription = {
        ...state.subscription,
        selectedProductPackage: defaultState.subscription.selectedProductPackage,
      };
      return { ...state, subscription };
    }

    case actions.LOAD_CAMPAIGNS: {
      const subscription = {
        ...state.subscription,
        campaigns: action.campaigns,
        filteredCampaigns: action.campaigns,
      };
      return { ...state, subscription };
    }

    case actions.LOAD_FILTERED_CAMPAIGNS: {
      const subscription = {
        ...state.subscription,
        filteredCampaigns: action.filteredCampaigns,
      };
      return { ...state, subscription };
    }

    case actions.LOAD_API_FILTERED_CAMPAIGNS: {
      const subscription = {
        ...state.subscription,
        filteredCampaigns: action.filteredCampaigns,
      };
      return { ...state, subscription };
    }

    case actions.RESET_FILTERED_CAMPAIGNS: {
      const subscription = {
        ...state.subscription,
        filteredCampaigns: defaultState.subscription.filteredCampaigns,
      };
      return { ...state, subscription };
    }

    case actions.SET_SELECTED_CAMPAIGN: {
      const oldCampaign = state.subscription.selectedCampaign || {};
      const subscription = {
        ...state.subscription,
        selectedCampaign:
          action.selectedCampaign.campno === oldCampaign.campno
            ? oldCampaign
            : action.selectedCampaign,
      };
      return { ...state, subscription };
    }

    case actions.RESET_SELECTED_CAMPAIGN: {
      const subscription = {
        ...state.subscription,
        selectedCampaign: defaultState.subscription.selectedCampaign,
        campaignCoProducts: defaultState.subscription.campaignCoProducts,
      };
      return { ...state, subscription };
    }

    case actions.LOAD_CAMPAIGN_CO_PRODUCTS: {
      const subscription = {
        ...state.subscription,
        campaignCoProducts: action.coProducts,
      };
      return { ...state, subscription };
    }

    case actions.LOAD_CAMPAIGN_CO_PRODUCTS_BY_SUBSCRIPTION: {
      const subscription = {
        ...state.subscription,
        campaignCoProductsBySubscription: action.coProductsBySubs,
      };
      return { ...state, subscription };
    }

    case actions.RESET_PRODUCT_PACKAGE: {
      const subscription = {
        ...state.subscription,
        productPackage: defaultState.subscription.productPackage,
      };
      return { ...state, subscription };
    }

    case actions.LOAD_SUBSCRIPTION_TYPES: {
      const subscription = {
        ...state.subscription,
        subscriptionTypes: action.payload,
      };
      return { ...state, subscription, subscriptionTypes: action.payload };
    }

    case actions.ADD_PRODUCT_TO_SUBSCRIPTION: {
      const subscription = {
        ...state.subscription,
        subscriptionProducts: [...state.subscription.subscriptionProducts, action.product],
      };
      return { ...state, subscription };
    }

    case actions.REMOVE_PRODUCT_FROM_SUBSCRIPTION: {
      const subscription = {
        ...state.subscription,
        subscriptionProducts: action.subscriptionProducts,
      };
      return { ...state, subscription };
    }

    case actions.RELOAD_SUBSCRIPTION_PRODUCTS: {
      const subscription = {
        ...state.subscription,
        subscriptionProducts: action.subscriptionProducts,
      };
      return { ...state, subscription };
    }

    case actions.RESET_SUBSCRIPTION_PRODUCTS: {
      const subscription = {
        ...state.subscription,
        subscriptionProducts: defaultState.subscription.subscriptionProducts,
      };
      return { ...state, subscription };
    }

    case actions.SELECT_SUBSCRIPTION_PRODUCT: {
      const subscription = {
        ...state.subscription,
        selectedSubscriptionProduct: action.product,
      };
      return { ...state, subscription };
    }

    case actions.SET_COMMUNECODE: {
      const subscription = {
        ...state.subscription,
        communeCode: action.communeCode,
      };
      return { ...state, subscription };
    }

    case actions.SET_SHOULD_RELOAD_SUBSCRIPTIONS: {
      const subscription = {
        ...state.subscription,
        shouldReloadSubscriptions: action.shouldReloadSubscriptions,
      };
      return { ...state, subscription };
    }

    case actions.SET_CANCEL_REASONS: {
      const subscription = { ...state.subscription, cancelReasons: action.reasons };
      return { ...state, subscription };
    }

    case actions.SET_SUBMITTED_SUBSCRIPTIONS: {
      const subscription = {
        ...state.subscription,
        submittedSubscriptions: action.submittedSubscriptions,
      };
      return { ...state, subscription };
    }

    case actions.SET_SUBMIT_RESULT: {
      const subscription = {
        ...state.subscription,
        submitResult: {
          successfulSubmits: action.successfulSubmits,
          unsuccessfulSubmits: action.unsuccessfulSubmits,
        },
      };
      return { ...state, subscription };
    }

    case actions.EDIT_PRODUCT_IN_SUBSCRIPTION: {
      const subscriptionProducts = state.subscription.subscriptionProducts;
      const subscription = {
        ...state.subscription,
        subscriptionProducts: subscriptionProducts.map((product) => {
          if (product.basketId !== action.basketId) {
            return product;
          }
          return {
            ...product,
            ...action.product,
          };
        }),
      };
      return { ...state, subscription };
    }

    case actions.SET_AVAILABLE_DISCOUNTS: {
      const subscription = {
        ...state.subscription,
        availableDiscounts: action.discounts,
      };
      return { ...state, subscription };
    }

    case actions.RESET_AVAILABLE_DISCOUNTS: {
      const subscription = {
        ...state.subscription,
        availableDiscounts: defaultState.subscription.availableDiscounts,
      };
      return { ...state, subscription };
    }

    case actions.SET_ALL_AVAILABLE_DISCOUNTS: {
      const subscription = {
        ...state.subscription,
        allAvailableDiscounts: action.discounts,
      };
      return { ...state, subscription };
    }

    case actions.SET_PRICE_GROUPS: {
      const subscription = {
        ...state.subscription,
        priceGroups: sortObjects(action.priceGroups, "id"),
      };
      return { ...state, subscription };
    }

    // Build 3.6.4.2 (Issue #32834)
    case actions.SET_SINGLECOPY_PRICE_GROUPS: {
      const subscription = {
        ...state.subscription,
        singleCopyPriceGroups: sortObjects(action.priceGroups, "id"),
      };
      return { ...state, subscription };
    }

    case actions.RESET_PRICE_GROUPS: {
      const subscription = {
        ...state.subscription,
        priceGroups: defaultState.subscription.priceGroups,
      };
      return { ...state, subscription };
    }

    // Build 3.6.4.2 (Issue #32834)
    case actions.RESET_SINGLECOPY_PRICE_GROUPS: {
      const subscription = {
        ...state.subscription,
        singleCopyPriceGroups: defaultState.subscription.singleCopyPriceGroups,
      };
      return { ...state, subscription };
    }

    case actions.SET_AVAILABLE_MONTH_LENGTHS: {
      const subscription = {
        ...state.subscription,
        monthLengths: action.months,
      };
      return { ...state, subscription };
    }

    case actions.RESET_AVAILABLE_MONTH_LENGTHS: {
      const subscription = {
        ...state.subscription,
        monthLengths: defaultState.subscription.monthLengths,
      };
      return { ...state, subscription };
    }

    case actions.SET_CAMPAIGN_DETAILS: {
      const subscription = {
        ...state.subscription,
        selectedCampaign: {
          ...state.subscription.selectedCampaign,
          ...action.campaignDetails,
        },
      };
      return { ...state, subscription };
    }

    // Build 3.6.4.1 (Issue #33098)
    case actions.SET_SELECTEDSUBS_CAMPAIGN_DETAILS: {
      const subscription = {
        ...state.subscription,
        selectedSubsCampaignDetails: {
          // Build 3.6.4.1 (Issue #33511): seems wrong
          // ...state.subscription.selectedSubsCampaignDetails,
          ...action.campaignDetails,
        },
      };
      return { ...state, subscription };
    }

    case actions.SET_SUBSCRIPTION_INSTALMENTS: {
      const stateOriginalInstalments = state.subscription.originalInstalments;
      const subscription = {
        ...state.subscription,
        updatedInstalments: action.updatedInstalments,
        originalInstalments: action.originalInstalments || stateOriginalInstalments,
      };
      console.log({subscription})
      return { ...state, subscription };
    }

    case actions.SET_SELECTED_SUBS_INSTALMENT: {
      const subscription = {
        ...state.subscription,
        selectedSubsInstalment: action.selectedSubsInstalment,
      };
      return { ...state, subscription };
    }

    case actions.SET_SUBSCRIPTION_PAYER: {
      const subscription = {
        ...state.subscription,
        payer: action.payer,
      };
      return { ...state, subscription };
    }

    case actions.SET_SUBSCRIPTION_RECOMMENDER: {
      const subscription = {
        ...state.subscription,
        recommender: action.recommender,
      };
      return { ...state, subscription };
    }

    case actions.SET_SUBSCRIPTION_SALESMAN: {
      const subscription = {
        ...state.subscription,
        salesman: action.salesman,
      };
      return { ...state, subscription };
    }

    case actions.SET_CUSTOMER_INVOICE_MODES: {
      const subscription = {
        ...state.subscription,
        invoiceModes: action.invoiceModes,
        defaultInvoiceMode: action.defaultInvoiceMode,
      };
      return { ...state, subscription };
    }

    case actions.RESET_INVOICE_MODES: {
      const subscription = {
        ...state.subscription,
        invoiceModes: defaultState.subscription.invoiceModes,
      };
      return { ...state, subscription };
    }

    case actions.SET_VAT_TO_EXTRA_PRODUCT: {
      const subscription = {
        ...state.subscription,
        selectedExtraProduct: {
          ...state.subscription.selectedExtraProduct,
          ...action.vat,
        },
      };
      return { ...state, subscription };
    }

    case actions.SET_PCS_TO_EXTRA_PRODUCT: {
      const subscription = {
        ...state.subscription,
        selectedExtraProduct: {
          ...state.subscription.selectedExtraProduct,
          pcs: action.pcs,
        },
      };
      return { ...state, subscription };
    }

    case actions.SET_MODIFIED_PRICE_TO_EXTRA_PRODUCT: {
      const subscription = {
        ...state.subscription,
        selectedExtraProduct: {
          ...state.subscription.selectedExtraProduct,
          modifiedUnitPrice: action.modifiedUnitPrice,
        },
      };
      return { ...state, subscription };
    }

    case actions.LOAD_FREE_SUBS_GROUP: {
      const subscription = {
        ...state.subscription,
        freeSubsGroup: action.freeSubsGroup,
      };
      return { ...state, subscription };
    }

    case actions.SET_DELIVERY_ISSUE: {
      return { ...state, deliveryIssues: action.issues };
    }

    case actions.CLEAR_DELIVERY_ISSUES:
      return { ...state, deliveryIssues: defaultState.deliveryIssues };

    case actions.SET_SAME_ADDRESS_SUBSCRIPTIONS: {
      const sameAddressSubscriptions = {
        ...state.subscription.sameAddressSubscriptions,
        subscriptions: action.subscriptions,
      };
      const subscription = { ...state.subscription, sameAddressSubscriptions };

      return { ...state, subscription };
    }

    case actions.CLEAR_SAME_ADDRESS_SUBSCRIPTIONS: {
      const newState = defaultState.subscription.sameAddressSubscriptions;
      const subscription = { ...state.subscription, sameAddressSubscriptions: newState };
      return { ...state, subscription };
    }

    case actions.TOGGLE_SAME_ADDRESS_MODAL: {
      const sameAddressSubscriptions = {
        ...state.subscription.sameAddressSubscriptions,
        modalOpen: !state.subscription.sameAddressSubscriptions.modalOpen,
      };
      const subscription = { ...state.subscription, sameAddressSubscriptions };

      return { ...state, subscription };
    }

    case actions.SET_CUSTOMER_EVENTS: {
      const events = {};
      for (const item of action.events) {
        events[item.eventno] = { ...item };
      }

      return { ...state, events: { id: action.id, events: events, selected: null } };
    }

    // Build 3.6.3.4 (Issue #32668)
    case actions.SET_CUSTOMER_EVENTS_CHANGED: {
      const events = { ...state.events, changed: action.changed };
      return { ...state, events };
    }

    case actions.SELECT_CUSTOMER_EVENT: {
      const events = { ...state.events, selected: action.customerEvent };
      return { ...state, events };
    }

    case actions.CLEAR_CUSTOMER_EVENTS: {
      return { ...state, events: { ...defaultState.events } };
    }

    case actions.LOAD_SUBSCRIPTION_SLEEPS: {
      const sleeps = { ...state.sleeps, sleeps: action.sleeps };
      return { ...state, sleeps };
    }

    case actions.SET_SELECTED_SLEEP: {
      const sleeps = { ...state.sleeps, selectedSleep: action.sleep };
      return { ...state, sleeps };
    }

    case actions.SELECT_CANCEL_SUBS_ITEM: {
      const cancelSubscription = { ...state.cancelSubscription, selectedItem: action.item };
      return { ...state, cancelSubscription };
    }

    case actions.LOAD_PAID_AND_OPEN_ITEMS: {
      const cancelSubscription = {
        ...state.cancelSubscription,
        paidItems: action.paidItems,
        openItems: action.openItems,
      };
      return { ...state, cancelSubscription };
    }

    case actions.TOGGLE_SUBSCRIPTION_GIFT_MODAL: {
      const extraProduct = {
        ...state.extraProduct,
        subscriptionGiftModalOpen: !state.extraProduct.subscriptionGiftModalOpen,
      };
      return { ...state, extraProduct };
    }

    case actions.SET_ITEM_EXPENSES: {
      const subscription = {
        ...state.subscription,
        itemExpenseWithoutVat: action.itemExpenseWithoutVat,
        itemExpenseWithVat: action.itemExpenseWithVat,
      };
      return { ...state, subscription };
    }

    case actions.SET_GROSS_PRICES: {
      const subscription = {
        ...state.subscription,
        grossExclVat: action.grossExclVat,
        grossInclVat: action.grossInclVat,
      };
      return { ...state, subscription };
    }

    case actions.SET_SUBSCRIPTION_PAPER_PRODUCT: {
      const subscription = {
        ...state.subscription,
        subscriptionPaperProduct: action.product,
      };
      return { ...state, subscription };
    }

    case actions.SET_AVAILABLE_ITEM_LENGTHS: {
      const subscription = {
        ...state.subscription,
        standItemQty: action.standItemQty,
        perItemQty: action.perItemQty,
      };
      return { ...state, subscription };
    }

    case actions.SET_PAPER_GROUPS: {
      const subscription = {
        ...state.subscription,
        paperGroups: action.paperGroups,
      };
      return { ...state, subscription };
    }

    case actions.SET_GROUP_PAPERS: {
      const subscription = {
        ...state.subscription,
        groupPapers: action.groupPapers,
      };
      return { ...state, subscription };
    }

    case actions.LOAD_SALES_GROUP_PAPERS: {
      const salesman = {
        ...state.salesman,
        salesGroupPapers: action.salesGroupPapers,
      };
      return { ...state, salesman };
    }

    case actions.LOAD_SALES_GROUPS: {
      const salesman = {
        ...state.salesman,
        salesGroups: action.salesGroups,
      };
      return { ...state, salesman };
    }

    case actions.LOAD_SALES_FEE_GROUPS: {
      const salesman = {
        ...state.salesman,
        salesFeeGroups: action.salesFeeGroups,
      };
      return { ...state, salesman };
    }

    case actions.LOAD_SALES_PRIZES: {
      const salesman = {
        ...state.salesman,
        salesPrizes: action.salesPrizes,
      };
      return { ...state, salesman };
    }

    case actions.LOAD_MARKETING_PERMISSIONS: {
      return { ...state, marketingPermissions: action.marketingPermissions };
    }

    case actions.CLEAR_MARKETING_PERMISSIONS: {
      return { ...state, marketingPermissions: defaultState.marketingPermissions };
    }

    case actions.ADD_HIGHLIGHT_TO_SUBSPRODUCT: {
      const subscription = {
        ...state.subscription,
        highlightedSubscriptionProduct: action.rowId,
      };
      return { ...state, subscription };
    }

    case actions.CLEAR_HIGHLIGHT_FROM_SUBSPRODUCT: {
      const subscription = {
        ...state.subscription,
        highlightedSubscriptionProduct: defaultState.subscription.highlightedSubscriptionProduct,
      };
      return { ...state, subscription };
    }

    // Build 4.1.1.0 (ERP #11124)
    case actions.ADD_HIGHLIGHT_TO_DYNAMIC_PRODUCT_SUBSPRODUCT: {
      const subscription = {
        ...state.subscription,
        highlightedSubscriptionProduct: action.rowId,
      };
      return { ...state, subscription };
    }

    // Build 4.1.1.0 (ERP #11124)
    case actions.CLEAR_HIGHLIGHT_FROM_DYNAMIC_PRODUCT_SUBSPRODUCT: {
      const subscription = {
        ...state.subscription,
        highlightedSubscriptionProduct: defaultState.subscription.highlightedSubscriptionProduct,
      };
      return { ...state, subscription };
    }

    case actions.REMOVE_INVNO_FROM_SUBS_INSTALMENTS: {
      const updatedInstalments = state.subscription.updatedInstalments.map((instalment) => {
        return { ...instalment, invoiceNo: "0" };
      });
      const originalInstalments = state.subscription.originalInstalments.map((instalment) => {
        return { ...instalment, invoiceNo: "0" };
      });

      const subscription = {
        ...state.subscription,
        updatedInstalments: updatedInstalments,
        originalInstalments: originalInstalments,
      };
      return { ...state, subscription };
    }

    case actions.UPDATE_INSTALMENTS_WITH_NEW_DUE_DATE: {
      const updatedInstalments = state.subscription.updatedInstalments.map((instalment) => {
        // Use itemno as index when replacing existing expdate
        const itemno = parseInt(instalment.instalment);
        const currentDueDates = action.dueDates[itemno - 1] || {};
        return {
          ...instalment,
          expDate: currentDueDates.dueDate,
        };
      });

      const subscription = {
        ...state.subscription,
        updatedInstalments: updatedInstalments,
      };
      return { ...state, subscription };
    }

    case actions.UPDATE_SINGLE_EXTRA_PRODUCT_INSTALMENTS: {
      const updatedSubscriptionProducts = [];
      let extraProductsAdded = false;
      state.subscription.subscriptionProducts.forEach((product) => {
        if (product.tableProduct === action.extraProducts[0].tableProduct && !extraProductsAdded) {
          extraProductsAdded = true;
          updatedSubscriptionProducts.push(...action.extraProducts);
        } else if (product.tableProduct !== action.extraProducts[0].tableProduct) {
          updatedSubscriptionProducts.push(product);
        }
      });

      const subscription = {
        ...state.subscription,
        subscriptionProducts: updatedSubscriptionProducts,
      };

      return { ...state, subscription };
    }

    case actions.UPDATE_ALL_EXTRA_PRODUCT_INSTALMENTS: {
      // eslint-disable-next-line array-callback-return
      const paperProduct = state.subscription.subscriptionProducts.find((product) => {
        if (product.isPaper) {
          return product;
        }
      });

      const subscription = {
        ...state.subscription,
        subscriptionProducts: [paperProduct].concat([...action.extraProducts]),
      };

      return { ...state, subscription };
    }

    case actions.SET_SHOULD_ASK_CREATING_NEW_SUBS: {
      return {
        ...state,
        shouldAskCreatingNewSubs: action.flag,
      };
    }

    case actions.SET_AUTOGIRO: {
      return { ...state, autogiro: action.autogiro };
    }

    // #27610
    case actions.SHOULD_DISPLAY_CUSTOMER_CONFIRM_MODAL: {
      return { ...state, displayCustomerConfirmModal: action.flag };
    }

    // Build 3.6.2.2 (Issue #31348)
    case actions.CHANGING_CUSTOMER_PROPERTIES: {
      return { ...state, changingCusProperties: action.flag };
    }

    // Build 3.6.2.2 (Issue #31348)
    case actions.SET_PREFER_INVMODE: {
      return { ...state, preferInvmodes: action.preferInvmodes };
    }

    // Build 3.6.2.2 (Issue #31348)
    case actions.SET_INVMODE_PAPERLIST: {
      // Build 4.1.0.0 (ERP #11674), get papercode list for all invmodes once to optimize customermodal
      if (action.paperlist.length > 0) {
        let tempInvmodePaperList = [];
        let paperListSingleInvmode = [];
        let prevInvmode = action.paperlist[0].invmode;
        action.paperlist.forEach((invmodePaper) => {
          if (invmodePaper.invmode !== prevInvmode) {
            tempInvmodePaperList[prevInvmode] = paperListSingleInvmode;
            paperListSingleInvmode = [];
            prevInvmode = invmodePaper.invmode;
          }
          paperListSingleInvmode.push(invmodePaper);
        });
        // add the last invmode
        tempInvmodePaperList[prevInvmode] = paperListSingleInvmode;

        return { ...state, invmodePaperList: tempInvmodePaperList };
      } else {
        return { ...state, invmodePaperList: [] };
      }
    }

    // Build 3.6.4.2 (Issue #32834)
    case actions.SET_SELECTED_SINGLESUBS_PERIODS: {
      const subscription = {
        ...state.subscription,
        singleSubsPeriods: action.singleSubsPeriods,
      };
      return { ...state, subscription };
    }

    // Build 3.6.4.2 (Issue #33372)
    case actions.SET_MARKETING_MATERIAL: {
      return { ...state, marketingMaterials: action.materials };
    }

    // Build 3.4.6.2 (Issue #33409)
    case actions.SET_HISTORY_SELECTION: {
      return { ...state, historyCustomerNo: action.cusno };
    }

    // Build 3.6.4.2 (Issue #26462)
    case actions.LOAD_WINSALE_TYPES: {
      return { ...state, winsaleTypes: action.winsaleTypes };
    }

    // Build 3.6.4.2 (Issue #12471)
    case actions.LOAD_POST_MACHINES: {
      return { ...state, rowLength: action.rowLength };
    }

    // Build 4.1.2.0 (ERP #12421)
    case actions.SET_SUBSCRIPTION_VALID_INVOICE_MODES: {
      const subscription = {
        ...state.subscription,
        subscriptionValidInvModes: action.subscriptionValidInvModes,
        // defaultInvoiceMode: action.defaultInvoiceMode,
      };
      return { ...state, subscription };
    }

    /* case actions.RESET_INVOICE_MODES: {
      const subscription = {
        ...state.subscription,
        invoiceModes: defaultState.subscription.invoiceModes,
      };
      return { ...state, subscription };
    } */

    // (Issue #10814)
    case actions.GET_CUSTOMER_MODULE_RIGHTS: {
      return { ...state, moduleRights: action.payload };
    }

    // Build 3.6.4.2 (ERP #11828)
    case actions.SET_CONTACT_INFOS: {
      return {
        ...state,
        selectedCustomer: { ...state.selectedCustomer, contactinfos: action.contactinfos },
      };
    }

    default:
      return state;
  }
};

export default customerReducer;
