import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getCancelReasons } from "../../actions/customer.js";
import { getSystemParameters, getEmptyAddresses } from "../../actions/parameter.js";

import Loading from "./ui/Loading.jsx";
import Header from "./header/index.jsx";
import Navigation from "./navigation/index.jsx";
import EmailContainer from "./email/index.jsx";
import DummyContainer from "../../containers/report/DummyContainer.jsx";

const Customer = React.lazy(() => import("../../containers/customer/Customer.jsx"));
const CoDistribution = React.lazy(() =>
  import("../../containers/coDistribution/CoDistribution.jsx")
);
const UserFeedbacksContainer = React.lazy(() => import("../../containers/user/UserFeedbacks.jsx"));
const UserCustomerContactsContainer = React.lazy(() =>
  import("../../containers/user/UserCustomerContacts.jsx")
);
// const Reports = React.lazy(() => import("../../containers/Reports.jsx")); old reports component
const UserManagement = React.lazy(() => import("@views/usersAdminPage/index.jsx"));
const ParametersGroupManagement = React.lazy(() => import("@views/parametersGroupAdminPage/index.jsx"));
const ParametersManagement = React.lazy(() => import("@views/parametersAdminPage/index.jsx"));
const Reports = React.lazy(() => import("@views/reportsManagement/index.jsx"));
const ProductManagement = React.lazy(() => import("@views/productManagement/index.jsx"));
const Product = React.lazy(() => import("@views/productManagement/Product.jsx"));
const PriceListManagement = React.lazy(() => import("@views/priceListManagement/index.jsx"));
const InvoicingPlan = React.lazy(() => import("@views/invoicingPlan/index.jsx"));
const InvoicingProgram = React.lazy(() => import("@views/invoicingPlan/InvoicingProgram.jsx"));
const CampaignManagement = React.lazy(() => import("@views/campaignManagement/index.jsx"));
const DigitalProductManagement = React.lazy(() => import("@views/digitalProductManagement/index.jsx"));





const fallbackContainerStyle = {
  position: "relative",
  width: "100%",
  height: "100%",
};

const Fallback = () => (
  <div style={fallbackContainerStyle}>
    <Loading loading={true} />
  </div>
);

/**
 * Index route which redirects to another component (Customer in this case).
 */
const IndexRoute = () => <Route render={() => <Redirect to={{ pathname: "/customer/" }} />} />;

const NavigationWithRouter = withRouter(Navigation);

/**
 * Application's main container. Header and side navigation are always available
 * and the main content is defined inside Switch, defaulting to home page.
 */
class Main extends React.Component {
  static propTypes = {
    // state
    isAdmin: PropTypes.bool.isRequired,
    // action dispatcher
    dispatch: PropTypes.func,
  };

  static defaultProps = { isAdmin: false };

  // A user is authenticated when execution comes here, so non-critical application
  // data can be fetched from the database here.
  componentDidMount() {
    this.props.dispatch(getCancelReasons());
    this.props.dispatch(getSystemParameters());
    this.props.dispatch(getEmptyAddresses());
  }

  // NOTE: When creating or updating application's Routes sidebar navigation also
  // needs to be udpated. Navigation needs same options as defined here so that
  // link highlighting and redirection works properly. (Navigation is defined
  // in the file ui/src/containers/Navigation.jsx)
  render() {
    const { isAdmin } = this.props;

    return (
      <div className="page-container">
        <Header />
        <div id="main-container">
          <NavigationWithRouter isAdmin={isAdmin} />
          <React.Suspense fallback={<Fallback />}>
            <Switch>
              <Route path="/customer/:cusno?/:subsno?" component={Customer} />
              <Route path="/co-distribution/:subsno?" component={CoDistribution} />
              <Route path="/feedbacks/user" component={UserFeedbacksContainer} />
              <Route path="/customer-contacts/user" component={UserCustomerContactsContainer} />
              <Route path="/reports/new" component={DummyContainer} />
              {/* <Route path="/reports" component={Reports} />  */}
              <Route path="/reports" component={Reports} />
              {isAdmin && <Route path="/admin/user-management" component={UserManagement} />}
              {isAdmin && <Route path="/admin/parameters-group-management" component={ParametersGroupManagement} />}
              {isAdmin && <Route path="/admin/parameters-management/:codegroupno/:papercode" component={ParametersManagement} />}
              {isAdmin && <Route path="/admin/product-management/productList/:papercode" component={Product} />}
              {isAdmin && <Route path="/admin/product-management" component={ProductManagement} />}
              {isAdmin && <Route path="/admin/price-list-management" component={PriceListManagement} />}
              {isAdmin && <Route path="/admin/invoicing-plan/invoicingProgramList/:monetplanno" component={InvoicingProgram} />}
              {isAdmin && <Route path="/admin/invoicing-plan" component={InvoicingPlan} />}
              {isAdmin && <Route path="/admin/campaign-management" component={CampaignManagement} />}
              {isAdmin && <Route path="/admin/digital-product-management" component={DigitalProductManagement} />}

              
              <Route path="/" exact component={IndexRoute} />
              <Route component={IndexRoute} />
            </Switch>
          </React.Suspense>
        </div>
        <EmailContainer />
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  isAdmin: user.isAdmin,
});

export default connect(mapStateToProps)(Main);
