import { postImage } from "@app/imageBank/imageBank.effect.js";
import { notify } from "@app/notification/notification.action.js";
import * as actionCreator from "@utils/actionCreator.js";
import { tr } from "@utils/translation.js";
import * as effect from "./invoicingProgram.effect.js";

const SLICE = "invoicing";

export const ADMIN_PRICE_IS_LOADING_ALL_INVOICING_PROGRAM = `${SLICE}/IS_LOADING_ALL_INVOICING_PROGRAM`;
export const ADMIN_PRICE_IS_LOADING_ALL_INVOICING_CONTROL = `${SLICE}/IS_LOADING_ALL_INVOICING_CONTROL`;
export const ADMIN_PRICE_SET_ALL_INVOICING_PROGRAM = `${SLICE}/SET_ALL_INVOICING_PROGRAM`;
export const ADMIN_PRICE_SET_INVOICING_PROGRAM = `${SLICE}/SET_INVOICING_PROGRAM`;
export const ADMIN_PRICE_SET_INVOICING_CONTROL = `${SLICE}/SET_INVOICING_CONTROL`;
export const ADMIN_PRICE_LOADING_INVOICING_CONTROL = `${SLICE}/LOADING_INVOICING_CONTROL`;
export const ADMIN_PRICE_SET_INVOICING_SINGLE_CONTROL = `${SLICE}/SET_INVOICING_SINGLE_CONTROL`;
export const ADMIN_PRICE_LOADING_INVOICING_SINGLE_CONTROL = `${SLICE}/LOADING_INVOICING_SINGLE_CONTROL`;
export const ADMIN_PRICE_SET_INVOICING_PROVISION = `${SLICE}/SET_INVOICING_SINGLE_PROVISION`;
export const ADMIN_PRICE_LOADING_INVOICING_PROVISION = `${SLICE}/LOADING_INVOICING_SINGLE_PROVISION`;
export const ADMIN_PRICE_SET_INVOICING_SINGLE_CAMPAIGNS = `${SLICE}/SET_INVOICING_SINGLE_CAMPAIGNS`;
export const ADMIN_PRICE_LOADING_INVOICING_SINGLE_CAMPAIGNS = `${SLICE}/LOADING_INVOICING_SINGLE_CAMPAIGNS`;
export const ADMIN_PRICE_SET_INVOICING_FEE_GROUPS = `${SLICE}/SET_INVOICING_FEE_GROUPS`;
export const ADMIN_PRICE_SET_INVOICING_FEEP_GROUPS = `${SLICE}/SET_INVOICING_FEEP_GROUPS`;
export const ADMIN_PRICE_LOADING_INVOICING_FEE_GROUPS = `${SLICE}/LOADING_INVOICING_FEE_GROUPS`;
export const ADMIN_PRICE_LOADING_INVOICING_FEEP_GROUPS = `${SLICE}/LOADING_INVOICING_FEEP_GROUPS`;
export const ADMIN_PRICE_SET_INVOICING_REMINDER = `${SLICE}/SET_INVOICING_REMINDER`;
export const ADMIN_PRICE_SET_INVOICING_REMINDER_POSTCODES = `${SLICE}/SET_INVOICING_REMINDER_POSTCODES`;
export const ADMIN_PRICE_SET_INVOICING_COLLECTION_POSTCODES = `${SLICE}/SET_INVOICING_COLLECTION_POSTCODES`;
export const ADMIN_PRICE_SET_INVOICING_REMINDER_AREAS = `${SLICE}/SET_INVOICING_REMINDER_AREAS`;
export const ADMIN_PRICE_SET_INVOICING_COLLECTION_AREAS = `${SLICE}/SET_INVOICING_COLLECTION_AREAS`;
export const ADMIN_SET_REMOVING_INVOICING_REMINDER_POSTCODE = `${SLICE}/SET_REMOVING_INVOICING_REMINDER_POSTCODE`;
export const ADMIN_SET_REMOVING_INVOICING_COLLECTION_POSTCODE = `${SLICE}/SET_REMOVING_INVOICING_COLLECTION_POSTCODE`;
export const ADMIN_PRICE_SET_INVOICING_COLLECTION = `${SLICE}/SET_INVOICING_COLLECTION`;
export const ADMIN_PRICE_LOADING_INVOICING_COLLECTION = `${SLICE}/LOADING_INVOICING_COLLECTION`;
export const ADMIN_PRICE_LOADING_INVOICING_REMINDER = `${SLICE}/LOADING_INVOICING_REMINDER`;
export const ADMIN_IS_LOADING_ALL_INVOICING_PROGRAM = `${SLICE}/IS_LOADING_ALL_INVOICING_PROGRAM`;
export const ADMIN_SET_ALL_INVOICING_PROGRAM = `${SLICE}/SET_ALL_INVOICING_PROGRAM`;
export const ADMIN_SET_INVOICING_PROGRAM = `${SLICE}/SET_INVOICING_PROGRAM`;

export const TOGGLE_SUB_INVOICES_MODAL = `${SLICE}/TOGGLE_SUB_INVOICES_MODAL`;
export const TOGGLE_REMINDER_MODAL = `${SLICE}/TOGGLE_REMINDER_MODAL`;
export const TOGGLE_COLLECTION_MODAL = `${SLICE}/TOGGLE_COLLECTION_MODAL`;
export const TOGGLE_PROVISION_MODAL = `${SLICE}/TOGGLE_PROVISION_MODAL`;
export const TOGGLE_SINGLE_MODAL = `${SLICE}/TOGGLE_SINGLE_MODAL`;
export const TOGGLE_ADD_EDIT_PROGRAM_MODAL = `${SLICE}/TOGGLE_ADD_EDIT_PROGRAM_MODAL`;


export const requestAllInvoicingProgram = () => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_IS_LOADING_ALL_INVOICING_PROGRAM));

  const response = await effect.fetchAllInvoicingProgram();
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_SET_ALL_INVOICING_PROGRAM, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_SET_ALL_INVOICING_PROGRAM, response));
};

export const requestInvoicingProgram = (invoicingProgram) => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_IS_LOADING_ALL_INVOICING_PROGRAM));

  const response = await effect.fetchInvoicingProgram(invoicingProgram);
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_SET_INVOICING_PROGRAM, []));
    dispatch(notify(response.error.message || tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_SET_INVOICING_PROGRAM, response));
};

export const addInvoicingProgram = (invoicingProgram, callback = () => { }) => async (dispatch) => {
  const response = await effect.postInvoicingProgram(invoicingProgram);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }

  callback();
  dispatch(notify(tr("invoicingProgramAdded"), "success"));

  dispatch(requestInvoicingProgram(invoicingProgram));

};


export const ADMIN_INVOICING_PROGRAM_EDITED = `${SLICE}/INVOICING_PROGRAM_EDITED`;
export const editInvoicingProgram = (invoicingProgram, callback = () => { }) => async (dispatch) => {

  const response = await effect.putInvoicingProgram(invoicingProgram);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  callback();
  dispatch(notify(tr("invoicingProgramEdited"), "success"));
  // dispatch(actionCreator.createAction(ADMIN_INVOICING_PROGRAM_EDITED, invoicingProgram));

  dispatch(requestInvoicingProgram(invoicingProgram));

};

export const ADMIN_SET_REMOVING_INVOICING_PROGRAM = `${SLICE}/SET_REMOVING_INVOICING_PROGRAM`;
export const ADMIN_INVOICING_PROGRAM_REMOVED = `${SLICE}/INVOICING_PROGRAM_REMOVED`;

export const removeInvoicingProgram = (invoicingProgram, callback = () => { }) => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_INVOICING_PROGRAM, true));

  const response = await effect.deleteInvoicingProgram(invoicingProgram);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_INVOICING_PROGRAM, false));
    return;
  }
  callback();
  dispatch(notify(tr("invoicingProgramRemoved"), "success"));
  dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_INVOICING_PROGRAM, false));
  // dispatch(actionCreator.createAction(ADMIN_INVOICING_PROGRAM_REMOVED, invoicingProgram));

  dispatch(requestInvoicingProgram(invoicingProgram));
};
export const removeInvoicingReminderPostcode = (postcode, callback = () => { }) => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_INVOICING_REMINDER_POSTCODE, true));
  // debugger
  const response = await effect.deleteInvoiceReminderPostcode(postcode);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_INVOICING_REMINDER_POSTCODE, false));
    return;
  }
  callback();
  dispatch(notify(tr("invoicingReminderPostcodeRemoved"), "success"));
  dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_INVOICING_REMINDER_POSTCODE, false));
  // dispatch(actionCreator.createAction(ADMIN_INVOICING_PROGRAM_REMOVED, invoicingProgram));

  dispatch(requestInvoiceReminderPostcodes(postcode));
};
export const removeInvoicingReminderArea = (area, callback = () => { }) => async (dispatch) => {

  // dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_INVOICING_REMINDER_area, true));
  // debugger
  const response = await effect.deleteInvoiceReminderArea(area);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    // dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_INVOICING_REMINDER_POSTCODE, false));
    return;
  }
  callback();
  dispatch(notify(tr("invoicingReminderAreaRemoved"), "success"));
  // dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_INVOICING_REMINDER_POSTCODE, false));
  // dispatch(actionCreator.createAction(ADMIN_INVOICING_PROGRAM_REMOVED, invoicingProgram));

  dispatch(requestInvoiceReminderAreas(area));
};
export const removeInvoicingCollectionPostcode = (postcode, callback = () => { }) => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_INVOICING_COLLECTION_POSTCODE, true));
  // debugger
  const response = await effect.deleteInvoiceCollectionPostcode(postcode);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_INVOICING_COLLECTION_POSTCODE, false));
    return;
  }
  callback();
  dispatch(notify(tr("invoicingCollectionPostcodeRemoved"), "success"));
  dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_INVOICING_COLLECTION_POSTCODE, false));
  // dispatch(actionCreator.createAction(ADMIN_INVOICING_PROGRAM_REMOVED, invoicingProgram));

  dispatch(requestInvoiceCollectionPostcodes(postcode));
};
export const removeInvoicingCollectionArea = (area, callback = () => { }) => async (dispatch) => {

  // dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_INVOICING_Collection_area, true));
  // debugger
  const response = await effect.deleteInvoiceCollectionArea(area);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    // dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_INVOICING_Collection_POSTCODE, false));
    return;
  }
  callback();
  dispatch(notify(tr("invoicingCollectionAreaRemoved"), "success"));
  // dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_INVOICING_REMINDER_POSTCODE, false));
  // dispatch(actionCreator.createAction(ADMIN_INVOICING_PROGRAM_REMOVED, invoicingProgram));

  dispatch(requestInvoiceCollectionAreas(area));
};

export const SELECT_INVOICE_PROGRAM_ROW = `${SLICE}/SELECT_INVOICE_PROGRAM_ROW`;
export const selectInvoiceProgramRow = (row) => async (dispatch) => {
  dispatch(actionCreator.createAction(SELECT_INVOICE_PROGRAM_ROW, row));
};

//Modals toggling actions
export const toggleSubInvoicesModal = (isOpen) => async (dispatch) => {
  dispatch(actionCreator.createAction(TOGGLE_SUB_INVOICES_MODAL, isOpen));
};

export const toggleReminderModal = (isOpen) => async (dispatch) => {
  dispatch(actionCreator.createAction(TOGGLE_REMINDER_MODAL, isOpen));
};

export const toggleCollectionModal = (isOpen) => async (dispatch) => {
  dispatch(actionCreator.createAction(TOGGLE_COLLECTION_MODAL, isOpen));
};

export const toggleProvisionModal = (isOpen) => async (dispatch) => {
  dispatch(actionCreator.createAction(TOGGLE_PROVISION_MODAL, isOpen));
};

export const toggleSingleModal = (isOpen) => async (dispatch) => {
  dispatch(actionCreator.createAction(TOGGLE_SINGLE_MODAL, isOpen));
};
export const toggleInvoiceSumModal = (isOpen) => async (dispatch) => {
  dispatch(actionCreator.createAction(TOGGLE_INVOICE_SUM_MODAL, isOpen));
};




export const requestInvoiceControl = (invoiceControl) => async (dispatch) => {

  
  dispatch(actionCreator.createAction(ADMIN_PRICE_LOADING_INVOICING_CONTROL));
  const response = await effect.fetchInvoiceControl(invoiceControl);
  if (response.error) {
    if(response.error.errors == 'Invoice plan control not found'){
      const invoicePayload = {
        monetplanno: invoiceControl.monetplanno,
        invdate: invoiceControl.invdate,
        papercode: invoiceControl.papercode,
        inv_collect: "Y",
        inv_campaign: "N",
        inv_discharge: "N",
        inv_normal: "Y"
      }
      dispatch(addInvoiceControl(invoicePayload, true));
    }
    else{
      dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_CONTROL, []));
    }
    // dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_CONTROL, response));
};
export const requestInvoiceSingleControl = (invoiceSingleControl) => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_PRICE_LOADING_INVOICING_SINGLE_CONTROL));
  const response = await effect.fetchInvoiceSingleControl(invoiceSingleControl);
  if (response.error) {
    if(response.error.errors == 'Invoice single control not found'){
      const singlePayload = {
        monetplanno: invoiceSingleControl.monetplanno,
        invdate: invoiceSingleControl.invdate,
      }
      dispatch(addInvoiceSingleControl(singlePayload, true));
    }
    else{
      dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_SINGLE_CONTROL, []));
      dispatch(notify(tr("dbConnectFailure"), "error"));
    }
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_SINGLE_CONTROL, response));
};

export const requestInvoiceProvision = (invoiceProvision) => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_PRICE_LOADING_INVOICING_PROVISION));
  const response = await effect.fetchInvoiceProvision(invoiceProvision);
  if (response.error) {
    if(response.error.errors == 'Invoice feep control not found'){
      const provisionPayload = {
        monetplanno: invoiceProvision.monetplanno,
        rundate: invoiceProvision.invdate,
      }
      dispatch(addProvisionControl(provisionPayload, true));
    }
    else{
      dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_PROVISION, []));
    }
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_PROVISION, response));
};

export const requestSingleCampaigns = (invoiceCampaigns) => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_PRICE_LOADING_INVOICING_SINGLE_CAMPAIGNS));
  const response = await effect.fetchSingleCampaigns(invoiceCampaigns);
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_SINGLE_CAMPAIGNS, []));
    // dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_SINGLE_CAMPAIGNS, response));
};

export const requestFeeGroups = () => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_PRICE_LOADING_INVOICING_FEE_GROUPS));
  const response = await effect.fetchFeeGroups();
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_FEE_GROUPS, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_FEE_GROUPS, response));
};
export const requestFeepGroups = (params) => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_PRICE_LOADING_INVOICING_FEEP_GROUPS));
  const response = await effect.fetchFeepGroups(params);
  if (response.error) {
    if(response.error.errors == 'Invoice feep group not found'){
      dispatch(addFeepGroups({monetplanno: params.monetplanno, rundate: params.invdate, feegroupno: params.papercode}))
    }
    else{
      dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_FEEP_GROUPS, []));
    }
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_FEEP_GROUPS, response));
};
export const requestInvoiceReminder = (params) => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_PRICE_LOADING_INVOICING_REMINDER));
  const response = await effect.fetchInvoiceReminder(params);
  if (response.error) {
    if(response.error.errors == 'Invoice dun control not found'){
      const reminderPayload = {
        monetplanno: params.monetplanno,
        dundate: params.invdate,
      }
      dispatch(addInvoiceReminder(reminderPayload, true));
    }
    else{
      dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_REMINDER, []));
      dispatch(notify(tr("dbConnectFailure"), "error"));
    }
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_REMINDER, response));
};
export const requestInvoiceReminderPostcodes = (params) => async (dispatch) => {

  // dispatch(actionCreator.createAction(ADMIN_PRICE_IS_LOADING_ALL_INVOICING_SingleCONTROL));
  const response = await effect.fetchInvoiceReminderPostcodes(params);
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_REMINDER_POSTCODES, []));
    dispatch(response.error.errors || notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_REMINDER_POSTCODES, response));
};
export const requestInvoiceCollectionPostcodes = (params) => async (dispatch) => {

  // dispatch(actionCreator.createAction(ADMIN_PRICE_IS_LOADING_ALL_INVOICING_SingleCONTROL));
  const response = await effect.fetchInvoiceCollectionPostcodes(params);
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_COLLECTION_POSTCODES, []));
    // dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_COLLECTION_POSTCODES, response));
};
export const requestInvoiceReminderAreas = (params) => async (dispatch) => {

  // dispatch(actionCreator.createAction(ADMIN_PRICE_IS_LOADING_ALL_INVOICING_SingleCONTROL));
  const response = await effect.fetchInvoiceReminderAreas(params);
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_REMINDER_AREAS, []));
    // dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_REMINDER_AREAS, response));
};
export const requestInvoiceCollectionAreas = (params) => async (dispatch) => {

  // dispatch(actionCreator.createAction(ADMIN_PRICE_IS_LOADING_ALL_INVOICING_SingleCONTROL));
  const response = await effect.fetchInvoiceCollectionAreas(params);
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_COLLECTION_AREAS, []));
    dispatch(notify(response.error.errors || tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_COLLECTION_AREAS, response));
};
export const requestInvoiceCollection = (params) => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_PRICE_LOADING_INVOICING_COLLECTION));
  const response = await effect.fetchInvoiceCollection(params);
  if (response.error) {
    if(response.error.errors == 'Invoice unp control not found' || response.error.message == 'Resource Not Found'){
      const collectionPayload = {
        monetplanno: params.monetplanno,
        invdate: params.invdate,
        papercode: params.papercode
      }
      dispatch(addInvoiceCollection(collectionPayload, true));
    }
    else{
      dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_COLLECTION, []));
      dispatch(response.error.errors || notify(tr("dbConnectFailure"), "error"));
    }
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_COLLECTION, response));
};

export const addInvoiceControl = (invoiceControl, refetch = undefined, callback = () => { }) => async (dispatch) => {
  // console.log("InvoiceControl",InvoiceControl)
  const response = await effect.postInvoiceControl(invoiceControl);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  
  callback();
  if(!refetch){
    dispatch(notify(tr("invoicingControlAdded"), "success"));
  }
  if(refetch == true){
    dispatch(requestInvoiceControl(invoiceControl));
  }
};

export const addProvisionControl = (invoiceProvision, refetch = undefined, callback = () => { }) => async (dispatch) => {
  // console.log("invoiceProvision",invoiceProvision)
  const response = await effect.postInvoiceProvision(invoiceProvision);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }

  callback();
  if(!refetch){
    dispatch(notify(tr("invoicingProvisionAdded"), "success"));
  }
  if(refetch == true){
    dispatch(requestInvoiceProvision({invdate: invoiceProvision.rundate, monetplanno: invoiceProvision.monetplanno}));
  }
};

export const addInvoiceSingleControl = (invoiceSingleControl, refetch = undefined, callback = () => { }) => async (dispatch) => {
  const response = await effect.postInvoiceSingleControl(invoiceSingleControl);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }

  callback();
  if(!refetch){
    dispatch(notify(tr("invoicingSingleControlAdded"), "success"));
  }
  if(refetch == true){
    dispatch(requestInvoiceSingleControl(invoiceSingleControl));
  }
};

export const addInvoiceCampaigns = (invoiceCampaigns) => async (dispatch) => {
  const response = await effect.postInvoiceCampaigns(invoiceCampaigns);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(notify(tr("invoicingCampaigns"), "success"));
  // dispatch(requestInvoiceSingleControl());
};
export const updateInvoiceCampaigns = (invoiceCampaigns) => async (dispatch) => {
  const response = await effect.updateInvoiceCampaigns(invoiceCampaigns);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(notify(tr("invoicingCampaigns"), "success"));
  // dispatch(requestInvoiceSingleControl());
};
export const addFeepGroups = (invoiceFeepGroups) => async (dispatch) => {
  const response = await effect.postInvoiceFeeGroups(invoiceFeepGroups);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(notify(tr("invoicingFeepGroups"), "success"));
  dispatch(requestFeepGroups(invoiceFeepGroups));
  // dispatch(requestInvoiceSingleControl());
};
export const updateFeepGroups = (invoiceFeepGroups) => async (dispatch) => {
  const response = await effect.updateInvoiceFeeGroups(invoiceFeepGroups);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(notify(tr("invoicingFeepGroups"), "success"));
  // dispatch(requestInvoiceSingleControl());
};
export const addInvoiceReminder = (invoiceReminder, refetch = undefined,callback = () => { }) => async (dispatch) => {
  const response = await effect.postInvoiceReminder(invoiceReminder);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  callback()
  if(!refetch){
    dispatch(notify(tr("invoicingReminderAdded"), "success"));
  }
  if(refetch == true){
    dispatch(requestInvoiceReminder({invdate: invoiceReminder.dundate}))
  };
};
export const addInvoiceReminderPostcodes = (postcodes, callback = () => {}) => async (dispatch) => {
  const response = await effect.postInvoiceReminderPostcodes(postcodes);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  callback()
  dispatch(notify(tr("invoicingReminderPostcodesAdded"), "success"));
  dispatch(requestInvoiceReminderPostcodes({invdate: postcodes.invdate, monetplanno: postcodes.monetplanno}));
};
export const addInvoiceCollectionPostcodes = (postcodes, callback = () => {}) => async (dispatch) => {
  const response = await effect.postInvoiceCollectionPostcodes(postcodes);
  if (response.error) {
    // dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  callback();
  dispatch(notify(tr("invoicingCollectionPostcodesAdded"), "success"));
  dispatch(requestInvoiceCollectionPostcodes({invdate: postcodes.invdate, monetplanno: postcodes.monetplanno}));
};

export const addInvoiceReminderAreas = (areas) => async (dispatch) => {
  const response = await effect.postInvoiceReminderAreas(areas);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(notify(tr("invoicingReminderAreasAdded"), "success"));
  dispatch(requestInvoiceReminderAreas({invdate: areas.invdate, monetplanno: areas.monetplanno}));
};
export const addInvoiceCollectionAreas = (areas) => async (dispatch) => {
  const response = await effect.postInvoiceCollectionAreas(areas);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(notify(tr("invoicingCollectionAreasAdded"), "success"));
  dispatch(requestInvoiceCollectionAreas({invdate: areas.invdate, monetplanno: areas.monetplanno}));
};
export const updateInvoiceReminderPostcodes = (postcodes, callback = () => {}) => async (dispatch) => {
  const response = await effect.putInvoiceReminderPostcodes(postcodes);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  callback();
  dispatch(notify(tr("invoicingReminderPostcodesUpdated"), "success"));
  dispatch(requestInvoiceReminderPostcodes({invdate: postcodes.invdate, monetplanno: postcodes.monetplanno}));
};
export const updateInvoiceCollectionPostcodes = (postcodes) => async (dispatch) => {
  const response = await effect.putInvoiceCollectionPostcodes(postcodes);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(notify(tr("invoicingCollectionPostcodesUpdated"), "success"));
  dispatch(requestInvoiceCollectionPostcodes({invdate: postcodes.invdate, monetplanno: postcodes.monetplanno}));
};
export const addInvoiceCollection = (invoiceCollection, refetch = undefined, callback = () => { }) => async (dispatch) => {
  const response = await effect.postInvoiceCollection(invoiceCollection);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  callback()
  if(!refetch){
    dispatch(notify(tr("invoicingCollectionAdded"), "success"));
  }
  if(refetch == true){
    dispatch(requestInvoiceCollection(invoiceCollection));
  }
};

export const toggleAddEditProgramModal = (isOpen) => async (dispatch) => {
  dispatch(actionCreator.createAction(TOGGLE_ADD_EDIT_PROGRAM_MODAL, isOpen));
};

export const ADMIN_IS_LOADING_INVOICE_SUM = `${SLICE}/ADMIN_IS_LOADING_INVOICE_SUM`;
export const ADMIN_SET_INVOICE_SUM = `${SLICE}/ADMIN_SET_INVOICE_SUM`;
export const TOGGLE_INVOICE_SUM_MODAL = `${SLICE}/TOGGLE_INVOICE_SUM_MODAL`;
export const requestInvoiceSum = (invoicingProgram) => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_IS_LOADING_INVOICE_SUM));
  dispatch(actionCreator.createAction(TOGGLE_INVOICE_SUM_MODAL));

  const invClassHeading = await effect.fetchInvoiceClassHeading(invoicingProgram);
  if (invClassHeading.error) {
    dispatch(actionCreator.createAction(ADMIN_SET_INVOICE_SUM, {}));
    return;
  }


  const invClassDetails = await effect.fetchInvoiceClassDetails(invoicingProgram);
  if (invClassDetails.error) {
    dispatch(actionCreator.createAction(ADMIN_SET_INVOICE_SUM, {}));
    return;
  }

  dispatch(actionCreator.createAction(ADMIN_SET_INVOICE_SUM, { invClassHeading:invClassHeading[0], invClassDetails:invClassDetails }));

};

